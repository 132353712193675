import React, {useState} from 'react';
import Layout from "../Layout";
import {Button, Grid, TextareaAutosize, TextField} from "@mui/material";
import Typography from "@mui/material/Typography";
import Page from "../Page";


const ContactUs = (props) => {


    const [formData, setFormData] = useState([]);


    const handleChange = event => {
        const {name, value} = event.target;
        this.setFormData({[name]: value});
    }


    return (
        <>
            <Layout>
                <Page>
                    <Typography variant={"body1"}>
                        Please contact us at <a href="mailto:office@capitalofdemocracy.eu">office@capitalofdemocracy.eu</a> in case you have problems or questions.
                    </Typography>
                   {/* <Grid container direction="column" justify="center" alignItems="center" >
                        <Grid item>
                            <Typography textAlign={"left"} variant={"h2"}>Contact us</Typography>
                            <Typography textAlign={"left"} mt={1} variant={"body1"}>If you got any questions please do not hesitate to contact
                                us. </Typography>
                        </Grid>
                        <Grid item sx={{width:"80%"}}>
                            <form
                                id="contact-form"
                            >
                                <Grid item  >
                                    <TextField
                                        fullWidth
                                        sx={{width:"100%"}}
                                        required
                                        id="name"
                                        label="Name"
                                        name="userName"
                                        onChange={() => handleChange("userName")}
                                        margin="normal"
                                    />
                                </Grid>
                                <Grid item>
                                    <TextField
                                        fullWidth
                                        required
                                        id="email"
                                        label="Email"
                                        name="email"
                                        onChange={() => handleChange("email")}
                                        margin="normal"
                                    />
                                </Grid>
                                <Grid item>
                                    <TextField
                                        fullWidth
                                        required
                                        minRows={5}
                                        id="message"
                                        label="Message"
                                        name="message"
                                        onChange={() => handleChange("message")}
                                        margin="normal"
                                        multiline
                                        rowsMax="4"
                                    />
                                </Grid>
                                <Grid container direction="row" spacing={2} style={{marginTop: 20}}>
                                    <Grid item xs={12} md={12}>
                                        <Button
                                            type="submit"
                                            variant="contained"
                                            color="primary"
                                            fullWidth={true}
                                            className={"button-primary"}
                                        >
                                            Send
                                        </Button>
                                    </Grid>
                                </Grid>
                            </form>
                        </Grid>
                    </Grid>*/}
                </Page>
            </Layout>
        </>
    )

}


export default ContactUs;