import React, {Component, useEffect, useState} from "react";
import {IconButton} from "@mui/material";
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import {useLocation, useParams} from "react-router-dom";

export default class PlayAudioButton extends Component{
    constructor(props) {
        super(props);

       // this.audiosource = this.props.audiosource;
        this.audio = this.props.audiosource;

        this.state = {
            playing: false,

        }

        this.toggle = this.toggle.bind(this);
    }

    toggle() {
        let isPlaying = this.state.playing;

        if(isPlaying) {
            this.pauseAudio();
        }
        else {
            this.playAudio();
        }
    }

    playAudio() {
        this.audio.play();
        this.setState({playing: true});
    }

    pauseAudio() {
        this.audio.pause();
        this.setState({playing: false});
    }

    componentDidUpdate(prevProps) {
        if(this.props.audiosource != prevProps.audiosource) {
          /*  console.log("AUDIO CHANGE");
            console.log("PREVPROPS", prevProps);*/
            this.pauseAudio();

            this.audio = this.props.audiosource;
        }
    }

    componentDidMount() {
        this.audio.addEventListener('ended', () => this.toggle());
    }

    componentWillUnmount() {
        this.pauseAudio();
        this.audio.removeEventListener('ended', () => this.toggle());
    }

    render() {
        return (
            <IconButton
                size="large"
                onClick={this.toggle}
            >
                <VolumeUpIcon/>
            </IconButton>
        )
    }


}
/*const useReactPath = () => {
    const [path, setPath] = React.useState(window.location.pathname);
    const listenToPopstate = () => {
        const winPath = window.location.pathname;
        setPath(winPath);
    };

    React.useEffect(() => {
        window.addEventListener("popstate", listenToPopstate);
        return () => {
            window.removeEventListener("popstate", listenToPopstate);
        };
    }, []);
    return path;
};

const useAudio = audiosource => {
    const [audio, setAudio] = useState(new Audio(audiosource.audiosource));
    const [playing, setPlaying] = useState(false);

    const params = useParams();
    const location = useLocation();

    const path = useReactPath();

    const toggle = () => setPlaying(!playing);

    useEffect(() => {
            playing ? audio.play() : audio.pause();
        },
        [playing]
    );

    useEffect(() => {


        if (path) {

          /*  if (audiosource.audiosource) setAudio(new Audio(audiosource.audiosource))
            else setPlaying(false)

            return () => {
                audio.removeEventListener('ended', () => setPlaying(false));
                setPlaying(false);
                audio.pause()
            }*/
        //}
        /*    audio.addEventListener('ended', () => setPlaying(false));
            return () => {
              audio.removeEventListener('ended', () => setPlaying(false));
            };*/
    /*}, [location,params]);

    return [playing, toggle];
};


const PlayAudioButton = audiosource => {
    const [playing, toggle] = useAudio(audiosource.audiosource);

    return (
        <IconButton
            size="large"
            onClick={toggle}
        >
            <VolumeUpIcon/>
        </IconButton>
    )
}

export default PlayAudioButton;*/