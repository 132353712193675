import React, {useContext, useEffect} from "react";
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import {Divider, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Menu, MenuItem} from "@mui/material";
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import MoreVert from '@mui/icons-material/MoreVert';
import UserContext from "../context/user/userContext";
import {Link, useLocation, useNavigate, useParams, useSearchParams} from "react-router-dom";
import PlayAudioButton from "./PlayButton";
import {styled, useTheme} from "@mui/material/styles";
import MenuIcon from '@mui/icons-material/Menu';
import Drawer from '@mui/material/Drawer';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import EmailRoundedIcon from '@mui/icons-material/EmailRounded';
import HelpRoundedIcon from '@mui/icons-material/HelpRounded';
import ProgressBar from "./ProgressBar";
import LogoRight from "../res/img/logo_ecod_tr.png"
import HomeRoundedIcon from '@mui/icons-material/HomeRounded';
import Typography from "@mui/material/Typography";


const drawerWidth = 240;
//import audio from './###';
const DrawerHeader = styled('div')(({theme}) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}));

const IconLeftHandSide = styled(Box)(({theme}) => ({
    display: "flex",
    flexDirection: "row",
    flexBasis:"20%",
    [theme.breakpoints.down('lg')]: {

    },
    [theme.breakpoints.up('lg')]: {
        justifyContent:"center"
    }
}));

const IconCenter = styled(Box)(({theme}) => ({
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    flexBasis:"33%",
    [theme.breakpoints.down('lg')]: {
        flexDirection: "column",

        alignItems: "center",
    },
    [theme.breakpoints.up('lg')]: {

    }
}));

const IconRightHandSide = styled(Box)(({theme}) => ({
    display: "flex",
    justifyContent: "flex-start",
    flexBasis:"20%",
    alignItems: "center",
    [theme.breakpoints.down('lg')]: {
        alignItems: "flex-end",
        justifyContent: "flex-end"
    },
    [theme.breakpoints.up('lg')]: {
        alignItems: "flex-end",
        justifyContent: "center",
        fontSize: "2rem",


    }
}));

const styles = theme => ({
    // Load app bar information from the theme
    toolbar: theme.mixins.toolbar,
});

const TopAppBar = (props) => {
    const theme = useTheme();
    let iconLeftHandSide = <div></div>;
    let iconRightHandSide = <div></div>;
    const userContext = useContext(UserContext)
    const {loadUserMeta, loadUserVotings, loading, cities,updateUserPageTime} = userContext
    const [anchorEl, setAnchorEl] = React.useState(null);

    const {totalSteps, currentPosition, audioSrc} = props.data;
    //console.log(props.data)

    const [open, setOpen] = React.useState(false);
    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    const location = useLocation();

    const params = useParams();

    const [searchParams] = useSearchParams();

    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const navigate = useNavigate();

    const goBackToHome = event => {
        navigate(`/?uuid=${searchParams.get('uuid')}`)
    }

    const logo = (
        <Box
            component="img"
            sx={{
                content: {
                    xs: `url(${LogoRight})`,
                    lg: `url(${LogoRight})`,
                }
            }}
            alt="ecod Logo"
            height="3.8vh"
        />
    );

    const handleClose = () => {
        setAnchorEl(null);
    };

    useEffect(() => {
        //loadUserMeta()
        //loadUserVotings()
        /*  if (!loading) {

          }*/
    }, [loadUserMeta, loading, loadUserVotings, cities, params.cityId, params.cityStep])

    if (location.pathname === "/" || location.pathname === "/contact-us") {
        iconLeftHandSide = (
            <IconLeftHandSide>
                {logo}
            </IconLeftHandSide>
        );

        iconRightHandSide = (
            <IconRightHandSide>
                {props.data.audioSrc &&
                    <>
                        <PlayAudioButton audiosource={new Audio(props.data.audioSrc)}/>
                        <IconButton
                            aria-label="menu"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleMenu}
                            size="large"
                        >
                            <MoreVert/>
                        </IconButton>
                    </>
                }
                <IconButton
                    color="accent"
                    aria-label="open drawer"
                    edge="end"
                    onClick={handleDrawerOpen}
                    sx={{...(open && {display: 'none'})}}
                >
                    <MenuIcon/>
                </IconButton>
                {/*          <Menu
                    id="menu-appbar"
                    anchorEl={anchorEl}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    keepMounted
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                >
                    <MenuItem component={Link} to={`/tutorial?uuid=${searchParams.get('uuid')}`} onClick={handleClose}>Restart
                        Intro</MenuItem>
                    <MenuItem component={Link} onClick={handleClose}>Contact</MenuItem>
                </Menu>*/}
            </IconRightHandSide>
        );
    } else if (location.pathname.includes("/tutorial")) {
        iconLeftHandSide = (
            <IconLeftHandSide>
                {logo}
            </IconLeftHandSide>
        );

        // iconRightHandSide = (
        //     <IconRightHandSide>
        //         {props.data.audioSrc &&
        //             <PlayAudioButton audiosource={new Audio(audioSrc)}/>
        //         }
        //         <IconButton
        //             aria-label="account of current user"
        //             aria-controls="menu-appbar"
        //             aria-haspopup="true"
        //             onClick={handleMenu}
        //             size="large"
        //         >
        //             <MoreVert/>
        //         </IconButton>
        //         <Menu
        //             id="menu-appbar"
        //             anchorEl={anchorEl}
        //             anchorOrigin={{
        //                 vertical: 'top',
        //                 horizontal: 'right',
        //             }}
        //             keepMounted
        //             transformOrigin={{
        //                 vertical: 'top',
        //                 horizontal: 'right',
        //             }}
        //             open={Boolean(anchorEl)}
        //             onClose={handleClose}
        //         >
        //             <MenuItem component={Link} to={`/tutorial/1/?uuid=${searchParams.get('uuid')}`} onClick={handleClose}>Restart
        //                 Intro</MenuItem>
        //             {!location.pathname.includes('tutorial') &&
        //             <MenuItem component={Link} to={`/contact/?uuid=${searchParams.get('uuid')}`} onClick={handleClose}>Contact</MenuItem>
        //             }
        //         </Menu>
        //     </IconRightHandSide>
        //);
    } else {
        iconLeftHandSide = (
            <IconLeftHandSide>
                <IconButton size="large" component={Link} to={`/?uuid=${searchParams.get('uuid')}`}>
                    <ArrowBackIcon/>
                </IconButton>

            </IconLeftHandSide>
        );

        iconRightHandSide = (
            <IconRightHandSide>
                {props.data.audioSrc &&
                    <PlayAudioButton audiosource={new Audio(audioSrc)}/>
                }
                <IconButton
                    aria-label="account of current user"
                    aria-controls="menu-appbar"
                    aria-haspopup="true"
                    onClick={handleMenu}
                    size="large"
                >
                    <MoreVert/>
                </IconButton>
                <Menu
                    id="menu-appbar"
                    anchorEl={anchorEl}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    keepMounted
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                >
                    <MenuItem component={Link} to={`/?uuid=${searchParams.get('uuid')}`}
                              onClick={handleClose}>
                        Dashboard
                    </MenuItem>
                    <MenuItem component={Link} to={`/contact-us/?uuid=${searchParams.get('uuid')}`} onClick={handleClose}>
                        Contact Us
                    </MenuItem>
                    <MenuItem component={Link} to={`/tutorial/1/?uuid=${searchParams.get('uuid')}`}
                              onClick={handleClose}>
                        Restart Intro
                    </MenuItem>
                </Menu>
            </IconRightHandSide>
        );

    }
    return (
        <>
            <AppBar position="fixed" elevation={0} sx={{justifyContent: {xs: "space-between", lg: "flex-end"}}}>
                <Toolbar sx={{justifyContent: "space-between", alignItems: "center"}}>

                    {iconLeftHandSide}
                    {props.data.cityName &&
                        <IconCenter>
                            <Typography variant={"h5"} align={"center"}
                                        fontSize={"large"}>{props.data.cityName} </Typography>
                        </IconCenter>
                    }
                    {iconRightHandSide}
                </Toolbar>
                {totalSteps > 0 && currentPosition > 0 &&
                    <ProgressBar total={totalSteps} position={currentPosition}
                                 sx={{position: "relative", bottom: 0}}></ProgressBar>
                }
            </AppBar>
            <Toolbar sx={{minHeight: "0px"}}/>
            <Toolbar></Toolbar>
            {props.children}
            <Drawer
                sx={{
                    width: drawerWidth,
                    flexShrink: 0,
                    '& .MuiDrawer-paper': {
                        width: drawerWidth,
                    },
                }}
                variant="persistent"
                anchor="right"
                open={open}
            >
                <DrawerHeader>
                    <IconButton onClick={handleDrawerClose}>
                        {theme.direction === 'rtl' ? <ChevronLeftIcon/> : <ChevronRightIcon/>}
                    </IconButton>
                </DrawerHeader>
                <Divider/>
                <List>
                    <ListItem disablePadding component={Link} to={`/?uuid=${searchParams.get('uuid')}`}>
                        <ListItemButton>
                            <ListItemIcon>
                                <HomeRoundedIcon></HomeRoundedIcon>
                            </ListItemIcon>
                            <ListItemText primary={"Dashboard"}/>
                        </ListItemButton>
                    </ListItem>
                    <ListItem disablePadding component={Link} to={`/contact-us?uuid=${searchParams.get('uuid')}`}>
                        <ListItemButton>
                            <ListItemIcon>
                                <EmailRoundedIcon></EmailRoundedIcon>
                            </ListItemIcon>
                            <ListItemText primary={"Contact Us"}/>
                        </ListItemButton>
                    </ListItem>
                    <ListItem disablePadding>
                        <ListItemButton compoinent={Link} to={`/tutorial/1?uuid=${searchParams.get('uuid')}`}>
                            <ListItemIcon>
                                <HelpRoundedIcon></HelpRoundedIcon>
                            </ListItemIcon>
                            <ListItemText primary={"Restart Intro"}/>
                        </ListItemButton>
                    </ListItem>
                </List>
            </Drawer>

        </>
    );
}

export default TopAppBar;