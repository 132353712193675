const cityJson = {
  "cities": [
    {
      "cityId": 1,
      "cityName": "Bratislava",
      "cityImage": "/assets/city1/23ECoD_bratislava_logo.png",
      "shortDesc": "A trustworthy space for everyone",
      "countryCode": "SVK", // imporant! all capital letters
      "pdfSource": "https://capitalofdemocracy.eu/ecod-application-bratislava/",
      "abbr": "BTS",
      "pages": [
        {
          "id": 1,
          "nextId": 2,
          "heading": "<h1>Bratislava - Facts (1/2)</h1>",
          "content": "<p class='ecod-city-detail-text'><ul><li>Region: Bratislava</li><li>Country: Slovakia</li><li>Population: 659,600</li><li>Official language: Slovak</li><li>Website: <a target='_blank' href='https://bratislava.sk/en/'>https://bratislava.sk</a></li></ul></p>",
          "audioSrc": "",
          "videoSrc": ""
        },
        {
          "id": 2,
          "nextId": 3,
          "prevId": 1,
          "heading": "<h1>Bratislava - Facts (2/2)</h1>",
          "content": "<img class='ecod-city-image' src='/assets/city1/ECoD23_Bratislava_map1.jpg'> </img><img class='ecod-city-image' src='/assets/city1/23ECoD_Bratislava_map2.jpg'> </img>",
          "audioSrc": "",
          "videoSrc": ""
        },
        {
          "id": 3,
          "nextId": 4,
          "prevId": 2,
          "heading": "<h1>Bratislava - Video</h1><h2>Matú<span class='ecod-special-char' style='font-family: unset !important'>š</span> Vallo (Team Bratislava), Mayor of Bratislava</h2>",
          "content": "",
          "audioSrc": "",
          "videoSrc": "/assets/city1/23ECoD_bratislava_video.mp4"
        },
        {
          "id": 4,
          "nextId": 5,
          "prevId": 3,
          "heading": "<h1>Bratislava - Summary (1/2)</h1><h2>A trustworthy space for everyone</h2>",
          "content": "<p class='ecod-city-detail-text' >Bratislava is <b>one of the youngest capitals in Europe</b>. It aims to strengthen democracy by implementing projects based on democratic values. To support its diverse society, the city plays a crucial role in <b>bringing its inhabitants together and solving conflicts</b>. Through concrete actions, Bratislava effectively <b>integrates the principles of democracy into daily life</b>, creating a trustworthy space for all citizens.</p>",
          "audioSrc": "",
          "videoSrc": ""
        },
        {
          "id": 5,
          "nextId": 6,
          "prevId": 4,
          "heading": "<h1>Bratislava - Summary (2/2)</h1><h2>A trustworthy space for everyone</h2>",
          "content": "<p class='ecod-city-detail-text' ><b>Public space is an important real-life factor of democracy</b>, and is a concept that Bratislava aims to foster together with civil society and its residents. Over the past 35 years, public spaces in post-communist countries have undergone <b>significant transformation</b>. Before, they were considered to be <b>everyone's and no one's place</b> where sharing opinions was unsafe. Now, they have evolved into <b>open areas for the free expression of political views</b>.</p>",
          "audioSrc": "",
          "videoSrc": ""
        },
        {
          "id": 6,
          "nextId": 7,
          "prevId": 5,
          "heading": "<h1>Bratislava - Project A (1/2)</h1><h2>The City for Children</h2>",
          "content": "<p class='ecod-city-detail-text' ><b>The City for Children</b> puts children's well-being in urban planning first, to <b>create an open and child-friendly environment</b>. Now pupils are included in decision-making and they engage in matters that affect their well-being, such as safety or access to public spaces. <b>Children are empowered to express their needs and opinions</b>. Decision-makers are reminded of the importance of considering their perspective.</p>",
          "audioSrc": "",
          "videoSrc": ""
        },
        {
          "id": 7,
          "nextId": 8,
          "prevId": 6,
          "heading": "<h1>Bratislava - Project A (2/2)</h1><h2>The City for Children</h2>",
          "content": "<p class='ecod-city-detail-text' ><b>Play Streets</b> have been a remarkable outcome, <b>transforming spaces dominated by cars into inclusive, safe, and democratic public areas</b>. The concept boosts active modes of transportation, like walking and cycling, while reducing traffic. By winning these areas back for recreation, communities experience <b>strengthened social connections, increased physical activity, and a higher quality of life</b>.</p><p class='ecod-city-detail-text' >In addition, an <b>international conference</b> is planned. There, city officials can exchange ideas, tackle shared challenges, and learn from experts, practitioners, and researchers.</p>",
          "audioSrc": "",
          "videoSrc": ""
        },
        {
          "id": 8,
          "nextId": 9,
          "prevId": 7,
          "heading": "<h1>Bratislava - Project B (1/2)</h1><h2>Climathon Bratislava</h2>",
          "content": "<p class='ecod-city-detail-text' ><b>Climathon Bratislava</b> fights climate change through innovative, smart solutions. The project responds to the threats of global warming. It brings together public servants, experts, private partners, and individual citizens. Together, they solve problems and <b>generate innovative solutions to soften the impacts of climate change</b>. The project raises awareness, provides education, and increases action. It supports a more <b>sustainable and climate-resilient future</b> for Bratislava and other cities.</p>",
          "audioSrc": "",
          "videoSrc": ""
        },
        {
          "id": 9,
          "nextId": 10,
          "prevId": 8,
          "heading": "<h1>Bratislava - Project B (2/2)</h1><h2>Climathon Bratislava<h2>",
          "content": "<p class='ecod-city-detail-text' >Organised by the city and its partners, <b>Climathon Bratislava</b> is part of a global movement. It brings together experienced mentors, practitioners, and technical and business leaders. Together with climate-change activists from Slovakia and abroad, they <b>address green action in the region</b>. The goal of this 48-hour event is to </b>design solutions to challenges set by the city</b> and then test them.</p>",
          "audioSrc": "",
          "videoSrc": ""
        },
        {
          "id": 10,
          "nextId": 11,
          "prevId": 9,
          "heading": "<h1>Bratislava - Project idea A (1/2)</h1><h2>Urban Childhood Conference</h2>",
          "content": "<p class='ecod-city-detail-text' >The <b>Urban Childhood Conference</b> is headed by the Metropolitan Institute of Bratislava and aims to <b>create child-friendly urban spaces</b>. It brings together city officials and experts to address common issues in urban planning for children. It fosters <b>knowledge exchange, best-practice sharing, and collaborative action</b> among participants. The event promotes inclusive decision-making and democratic values, bringing in global perspectives.</p>",
          "audioSrc": "",
          "videoSrc": ""
        },
        {
          "id": 11,
          "nextId": 12,
          "prevId": 10,
          "heading": "<h1>Bratislava - Project idea A (2/2)</h1><h2>Urban Childhood Conference</h2>",
          "content": "<p class='ecod-city-detail-text' >The first year of the conference is already in planning, including budget and organisation. <b>Starting in May 2024, the conference aims to establish itself as an annual space for knowledge exchange</b>. Keynote speakers, interactive workshops, and networking opportunities will be at the heart of the two-day event. Professionals, sponsors, philanthropists, and international partners will contribute. The event will <b>promote safe, inclusive, and sustainable urban environmental solutions for children</b> worldwide.</p>",
          "audioSrc": "",
          "videoSrc": ""
        },
        {
          "id": 12,
          "nextId": 13,
          "prevId": 11,
          "heading": "<h1>Bratislava - Project idea B (1/2)</h1><h2>Embracing the Art of Democracy</h2>",
          "content": "<p class='ecod-city-detail-text' ><b>Democratic Culture of Bratislava</b> tackles the challenge of <b>deepening connections between democracy and art</b>. While the city is known for its vibrant cultural scene, there is a need to engage citizens in conversations about the importance of democracy. <b>Citizen engagement regarding democratic values is vital</b>, particularly during the upcoming 35th Anniversary of the Velvet Revolution. The project aims to foster public discourse and understanding.</p>",
          "audioSrc": "",
          "videoSrc": ""
        },
        {
          "id": 13,
          "prevId": 12,
          "heading": "<h1>Bratislava - Project idea B (1/2)</h1><h2>Embracing the Art of Democracy</h2>",
          "content": "<p class='ecod-city-detail-text' >Connecting to the <b>Bratislava City Days</b> allows for <b>art exhibitions, performances, workshops, and interactive installations</b>. This integration of art and culture is an entertaining way to explore democracy, foster participation, facilitate critical thinking, and deepen comprehension of democratic principles.</p><p class='ecod-city-detail-text' >Collaborating with prestigious institutions, like the Bratislava City Gallery and the Slovak National Gallery, the project goes <b>beyond traditional exhibitions</b>. It enables citizens to engage with thought-provoking art, cultivating a culture of reflection and awareness.</p>",
          "audioSrc": "",
          "videoSrc": ""
        }
      ]
    },
    {
      "cityId": 2,
      "cityName": "Gdańsk",
      "cityImage": "/assets/city2/23ECoD_Gdansk_logo.png",
      "shortDesc": "In love with democracy - all together and everyone individually",
      "countryCode": "POL", // imporant! all capital letters
      "pdfSource": "https://capitalofdemocracy.eu/ecod-application-gdansk/",
      "abbr": "GDN",
      "pages": [
        {
          "id": 1,
          "nextId": 2,
          "heading": "<h1>Gdańsk - Facts (1/2)</h1>",
          "content": "<p class='ecod-city-detail-text' ><ul><li>Region: Pomerania</li><li>Country: Poland</li><li>Population: 486,492</li><li>Official language: Polish</li><li>Website: <a target='_blank' href='https://www.gdansk.pl/en/'>https://www.gdansk.pl</a></li></ul></p>",
          "audioSrc": "",
          "videoSrc": ""
        },
        {
          "id": 2,
          "nextId": 3,
          "prevId": 1,
          "heading": "<h1>Gdańsk - Facts (2/2)</h1>",
          "content": "<img class='ecod-city-image' src='/assets/city2/23ECoD_Gdansk_map1.jpg'> </img><img class='ecod-city-image' src='/assets/city2/23ECoD_Gdansk_map2.jpg'> </img>",
          "audioSrc": "",
          "videoSrc": ""
        },
        {
          "id": 3,
          "nextId": 4,
          "prevId": 2,
          "heading": "<h1>Gdańsk - Video</h1><h2>Aleksandra Dulkiewicz (Civic Platform), Mayor of Gdańsk</h2>",
          "content": "",
          "audioSrc": "",
          "videoSrc": "/assets/city2/23ECoD_Gdansk_video.mp4"
        },
        {
          "id": 4,
          "nextId": 5,
          "prevId": 3,
          "heading": "<h1>Gdańsk - Summary (1/2)</h1><h2>In love with democracy - all together and everyone individually</h2>",
          "content": "<p class='ecod-city-detail-text'><b>Democracy in Gdańsk is not a slogan</b>. It is the unceasing task of being a citizen. It is a way of <b>participating in community life to build local and European identity</b>. It is co-governance and co-responsibility for fellow residents, surroundings, and the planet. It is thinking of our city as a common good and planning its future on human rights, inclusion, and equal treatment. It is openness to newcomers, co-creation of democratic innovations, and empowering people. </p>",
          "audioSrc": "",
          "videoSrc": ""
        },
        {
          "id": 5,
          "nextId": 6,
          "prevId": 4,
          "heading": "<h1>Gdańsk - Summary (2/2)</h1><h2>In love with democracy - all together and everyone individually</h2>",
          "content": "<p class='ecod-city-detail-text'>Gdańsk is called the <b>City of Freedom and Solidarity</b>, and its entire history, both old and recent, shows that it is open, innovative, and progressive. Gdańsk is also a <b>learning city</b>. Learning from the best in Europe, we are a <b>member of many pan-European associations and networks</b>. Both the inhabitants and the city authorities translate democratic tradition and openness into civic activity, cooperation, and collaboration. In Gdańsk, the <b>civic sector is the driver of social development</b>.</p>",
          "audioSrc": "",
          "videoSrc": ""
        },
        {
          "id": 6,
          "nextId": 7,
          "prevId": 5,
          "heading": "<h1>Gdańsk - Project A (1/2)</h1><h2>Gdańsk Civics Lessons</h2>",
          "content": "<p class='ecod-city-detail-text' >Gdańsk has created a <b>modern ecosystem of democracy education</b> through a series of Civics Lessons. The <b>focus is on self-governance and the practice of democracy by school students</b>, as well as strengthening civic activity and co-responsibility for residents. The Civics Lessons are based on scenarios, co-created by teachers from the Creative Pedagogy Movement, followed by <b>open Q&A sessions with the Mayor</b>, which are held at a secondary school every month.</p>",
          "audioSrc": "",
          "videoSrc": ""
        },
        {
          "id": 7,
          "nextId": 8,
          "prevId": 6,
          "heading": "<h1>Gdańsk - Project A (2/2)</h1><h2>Gdańsk Civics Lessons</h2>",
          "content": "<p class='ecod-city-detail-text' >In terms of self-governance, <b>election campaigns and elections taking place on the same date in all schools</b>. Every student has the right to vote and run as a candidate, giving them <b>a chance to experience and practise democracy first hand</b>.</p><p class='ecod-city-detail-text' >One permanent element of this ecosystem is “Democracy Week” – an open event co-organised by youth and the European Solidarity Centre. In addition, the Youth Council advises the City Council and the Gdańsk Funds provide mini grants to groups of residents or NGOs for initiatives addressing their local community’s needs.</p>",
          "audioSrc": "",
          "videoSrc": ""
        },
        {
          "id": 8,
          "nextId": 9,
          "prevId": 7,
          "heading": "<h1>Gdańsk - Project B (1/2)</h1><h2>Citizens’ Assembly & Green Civic Budget</h2>",
          "content": "<p class='ecod-city-detail-text' ><b>Gdańsk has developed many tools for practising participatory democracy</b> and mechanisms for <b>engaging residents in decision making</b>. Gdańsk was the <b>first city in Poland to hold a Citizens’ Assembly</b> in 2016. A group of 60 residents were randomly selected and invited to address climate action with a focus on heavy rainfall. After a debate, they worked out recommendations which they then voted on. The most popular recommendations have already been implemented.</p>",
          "audioSrc": "",
          "videoSrc": ""
        },
        {
          "id": 9,
          "nextId": 10,
          "prevId": 8,
          "heading": "<h1>Gdańsk - Project B (2/2)</h><h2>Citizens’ Assembly & Green Civic Budget</h2>",
          "content": "<p class='ecod-city-detail-text' >The <b>Civic Budget, where residents vote annually</b> on a portion of the city’s budget, was introduced in Gdansk in 2013. In 2020, 20% of this budget (approximately EUR 1 million) became the <b>Green Civic Budget</b>. Only projects related to greening, protecting the natural environment, or adaptation to climate change are allowed. A total of 492 projects have been submitted so far, and 144 have been selected for implementation.</p>",
          "audioSrc": "",
          "videoSrc": ""
        },
        {
          "id": 10,
          "nextId": 11,
          "prevId": 9,
          "heading": "<h1>Gdańsk - Project idea A (1/3)</h1><h2>Centrum Dolna Brama Civic Hub</h2>",
          "content": "<p class='ecod-city-detail-text' ><b>Centrum Dolna Brama Civic Hub</b> is a fully-accessible <b>space for participatory democracy and social innovation</b>. It supports social trust and is an integral part of Gdańsk’s civil-society ecosystem. New spaces for activities run by residents and social organisations are required for the implementation of further social innovation programmes.</p>",
          "audioSrc": "",
          "videoSrc": ""
        },
        {
          "id": 11,
          "nextId": 12,
          "prevId": 10,
          "heading": "<h1>Gdańsk - Project idea A (2/3)</h1><h2>Centrum Dolna Brama Civic Hub</h2>",
          "content": "<p class='ecod-city-detail-text' ><b>Inclusion in democratic processes</b> and taking responsibility for the community <b>requires increased trust</b>. Therefore, it is important to have <b>a place where public administration, civil society organisations, community leaders, and other representatives can meet</b> to strengthen democracy and establish new social innovations.</p>",
          "audioSrc": "",
          "videoSrc": ""
        },
        {
          "id": 12,
          "nextId": 13,
          "prevId": 11,
          "heading": "<h1>Gdańsk - Project idea A (3/3)</h1><h2>Centrum Dolna Brama Civic Hub</h2>",
          "content": "<p class='ecod-city-detail-text' >A former school was redesigned for this purpose, providing meeting, co-working, seminar, and workshop spaces, as well as a kitchen. <b>Citizens and their organisations can use this space free-of-charge</b>. The Civic Hub will also create a City Lab – a place for the <b>implementation of social research projects for the civic sector</b> and a <b>social innovation incubator for experimental new practices</b> and debate.</p>",
          "audioSrc": "",
          "videoSrc": ""
        },
        {
          "id": 13,
          "nextId": 14,
          "prevId": 12,
          "heading": "<h1>Gdańsk - Project idea B (1/2)</h1><h2>Gdańsk City of Human Rights</h2>",
          "content": "<p class='ecod-city-detail-text' ><b>Democracy and human rights are closely linked</b>, with human rights increasingly seen as an essential element of good local governance. The Fundamental Rights Agency has shown that human rights are often </b>attributed to <i>others</i> – excluded groups, political prisoners, and victims of conflict</b>. The City of Gdańsk has adopted two local policies, co-created with stakeholders and beneficiaries. The Immigrant Integration Model (2016) aims to create a migration management system and the Equal Treatment Model (2018) aims to <b>increase the exercising of rights, equal opportunities, and participation to protect against discrimination</b>.</p>",
          "audioSrc": "",
          "videoSrc": ""
        },
        {
          "id": 14,
          "prevId": 13,
          "heading": "<h1>Gdańsk - Project idea B (2/2)</h1><h2>Gdańsk City of Human Rights</h2>",
          "content": "<p class='ecod-city-detail-text' >The evaluation of both models shows the <b>need for a systemic approach to inclusion and to safeguarding residents’ rights</b>. Gdańsk’s human-rights policies are assessed using the <b>Equality Impact Assessment Tool and Social Audit</b>. The outcomes indicate the need to adapt tools to Polish law and local conditions. NGOs and communities contribute, working for the benefit of excluded people. Tools will be piloted in chosen areas and evaluated before roll-out.</p>",
          "audioSrc": "",
          "videoSrc": ""
        }
      ]
    },
    {
      "cityId": 3,
      "cityName": "İzmir",
      "cityImage": "/assets/city3/ECoD23_Izmir_logo.png",
      "shortDesc": "Local Democracy for Global Harmony",
      "countryCode": "TUR", // imporant! all capital letters
      "pdfSource": "https://capitalofdemocracy.eu/ecod-application-izmir/",
      "abbr": "ADB",
      "pages": [
        {
          "id": 1,
          "nextId": 2,
          "heading": "<h1>İzmir - Facts (1/2)</h1>",
          "content": "<p class='ecod-city-detail-text' ><ul><li>Region: Aegean</li><li>Country: Türkiye</li><li>Population: 4,425,789</li><li>Official language: Turkish</li><li>Website: <a target='_blank' href='https://www.izmir.bel.tr/en/Home'>https://www.izmir.bel.tr</a></li></ul></p>",
          "audioSrc": "",
          "videoSrc": ""
        },
        {
          "id": 2,
          "nextId": 3,
          "prevId": 1,
          "heading": "<h1>İzmir - Facts (2/2)</h1>",
          "content": "<img class='ecod-city-image' src='/assets/city3/ECoD23_Izmir_map1.jpg'> </img><img class='ecod-city-image' src='/assets/city3/ECoD23_Izmir_map2.jpg'> </img>",
          "audioSrc": "",
          "videoSrc": ""
        },
        {
          "id": 3,
          "nextId": 4,
          "prevId": 2,
          "heading": "<h1>İzmir - Video</h1><h2>Tunç Soyer (Republican People's Party), Mayor of İzmir</h2>",
          "content": "",
          "audioSrc": "",
          "videoSrc": "/assets/city3/23ECoD_Izmir_video.mp4"
        },
        {
          "id": 4,
          "nextId": 5,
          "prevId": 3,
          "heading": "<h1>İzmir - Summary (1/2)</h1><h2>Local Democracy for Global Harmony</h2>",
          "content": "<p class='ecod-city-detail-text'>İzmir sits at the heart of the Aegean <b>where the primary social innovation of humanity, namely democracy, was born</b>. The city continues to embrace a <b>vibrant and diverse community that upholds universal democratic values</b>. Regrettably, in a world of multiple and entangled crises, democracy is in trouble. Local governments need to take action to address this problem.</p>",
          "audioSrc": "",
          "videoSrc": ""
        },
        {
          "id": 5,
          "nextId": 6,
          "prevId": 4,
          "heading": "<h1>İzmir - Summary (2/2)</h1><h2>Local Democracy for Global Harmony</h2>",
          "content": "<p class='ecod-city-detail-text'>İzmir fully embraces the current values and tools of democracy. Moreover, it offers a new <b>holistic perspective that transcends traditional methods of governance</b>. The city adopted a <b>circular mindset</b> where every decision is considered in terms of its connection to ecosystems, society, the past, and the future. In this way, it promotes inclusivity, respect, and continuous improvement. İzmir strongly <b>collaborates with various global and regional networks of cities</b> to enhance democracy in Europe and worldwide.</p>",
          "audioSrc": "",
          "videoSrc": ""
        },
        {
          "id": 6,
          "nextId": 7,
          "prevId": 5,
          "heading": "<h1>İzmir - Project A (1/2)</h1><h2>Congress for Future’s Economy</h2>",
          "content": "<p class='ecod-city-detail-text' >In the view of global crises, including the climate emergency, democracy challenges, and the pandemic, the <b>Congress for Future's Economy aims to redefine economic paradigms</b>. Preparations involved meetings with farmers, workers, industrialists, traders, and artisans, leading to three declarations. Expert groups analysed these declarations in the framework of <b>Circular Culture</b> that sits on four pillars: <b>Harmony with each other, harmony with nature, harmony with the past, and harmony with change.</b></p>",
          "audioSrc": "",
          "videoSrc": ""
        },
        {
          "id": 7,
          "nextId": 8,
          "prevId": 6,
          "heading": "<h1>İzmir - Project A (2/2)</h1><h2>Congress for Future’s Economy</h2>",
          "content": "<p class='ecod-city-detail-text' >The <b>main congress, held in March 2023</b>, was joined by 500 delegates and 200 experts from 180 organisations across Türkiye. The sessions <b>drew the interest of around 15,000 citizens</b>. After an 8-month preparation phase, the final declaration was voted on. This initiative aspires to <b>lay the foundation for Türkiye’s economic policies in the new century</b> in an open and transparent way. It extends an invitation from İzmir to Türkiye and the global community.</p>",
          "audioSrc": "",
          "videoSrc": ""
        },
        {
          "id": 8,
          "nextId": 9,
          "prevId": 7,
          "heading": "<h1>İzmir - Project B (1/2)</h1><h2>Immediate Solutions Team",
          "content": "<p class='ecod-city-detail-text' >The <b>Immediate Solutions Team (IST) enhances public-service standards</b> through people-oriented initiatives. <b>Focused on underserved areas</b>, IST collaborates with citizens to identify local service-needs, addressing inequalities in service delivery through social participation. The process comprises four stages: <b>1 - Neighbourhood analysis</b>, <b>2 - Exploration</b> by collecting feedback, <b>3 - Planning</b> to categorise requests, crafting an action plan, and <b>4 - Implementation</b>.</p>",
          "audioSrc": "",
          "videoSrc": ""
        },
        {
          "id": 9,
          "nextId": 10,
          "prevId": 8,
          "heading": "<h1>İzmir - Project B (2/2)</h1><h2>Immediate Solutions Team</h2>",
          "content": "<p class='ecod-city-detail-text' >The <b>Mayor and the team visit neighbourhoods, listening to citizens individually, and assign programmes to implementation teams</b>. So far almost 18,000 interviews have been conducted in six districts and 34 neighbourhoods, involving over 60,000 people. IST's activities include recreation-area development, school construction, sports-field creation, open-air cinemas, marketplace renovations, and more. It addresses issues raised by citizens through the Municipality’s communication centre, <b>engaging citizens in the decision-making process</b>.</p>",
          "audioSrc": "",
          "videoSrc": ""
        },
        {
          "id": 10,
          "nextId": 11,
          "prevId": 9,
          "heading": "<h1>İzmir - Project idea A (1/2)</h1><h2>WeIzmir Movement</h2>",
          "content": "<p class='ecod-city-detail-text' >Recognising the challenges of <b>achieving widespread citizen engagement in modern society</b>, the <b>WeIzmir Movement aims to revolutionise local governance</b> in İzmir by involving citizens in decision-making processes. This movement seeks to <b>enhance participation through neighbourhood councils and participatory budgeting</b>. Diverse community perspectives shape policies that address specific local needs.</p>",
          "audioSrc": "",
          "videoSrc": ""
        },
        {
          "id": 11,
          "nextId": 12,
          "prevId": 10,
          "heading": "<h1>İzmir - Project idea A (2/2)</h1><h2>WeIzmir Movement</h2>",
          "content": "<p class='ecod-city-detail-text' ><b>WeIzmir facilitates effective communication and collaboration among residents</b>, councils, local authorities, and stakeholders, <b>fostering transparent information sharing and informed decision-making</b>. Envisioning citizen involvement in municipal councils, the project plans to start participation in four districts initially. The goal is to expand across more than 15 counties in İzmir. The project aims to <b>empower residents, encouraging active engagement in the democratic process</b> and fostering a participatory democracy. It shapes communities through <b>collective wisdom and shared decision-making.</b></p>",
          "audioSrc": "",
          "videoSrc": ""
        },
        {
          "id": 12,
          "nextId": 13,
          "prevId": 11,
          "heading": "<h1>İzmir - Project idea B (1/3)</h1><h2>Northern Route – Yamanlar Road</h2>",
          "content": "<p class='ecod-city-detail-text' ><b>Northern Route – Yamanlar Road</b> demonstrates İzmir’s dedication to <b>promoting a sense of communal solidarity</b>. Rooted in the city's multicultural history, the project seeks to <b>break down socio-economic barriers, creating a cohesive and harmonious community</b>. The 18 km long road will be part of the İzmir Heritage Routes and <b>aims to connect the city centre with natural areas</b>. It promotes ecological corridors, circular economies, and cultural bonds between urban and rural zones.</p>",
          "audioSrc": "",
        },
        {
          "id": 13,
          "nextId": 14,
          "prevId": 12,
          "heading": "<h1>İzmir - Project idea B (2/3)</h1><h2>Northern Route – Yamanlar Road</h2>",
          "content": "<p class='ecod-city-detail-text' >Divided into two stages, the <b>project focuses on infrastructure upgrades, establishing recreational areas, and enhancing parks and villages</b>. The road will become <b>a platform for people from diverse backgrounds to connect and appreciate nature</b>. Active citizen involvement is key, and the Municipality conducted a survey, indicating strong interest from residents.</p>",
          "audioSrc": "",
          "videoSrc": ""
        },
        {
          "id": 14,
          "prevId": 13,
          "heading": "<h1>İzmir - Project idea B (1/3)</h1><h2>Northern Route – Yamanlar Road</h2>",
          "content": "<p class='ecod-city-detail-text' >The project is likely to attract 100,000 to 200,000 individuals annually. It envisions a <b>blend of urban and natural landscapes, promoting community interactions and harmony</b>. Through public events, media campaigns, and strategic signage, the Municipality will <b>raise awareness and encourage citizens to engage with and benefit from Yamanlar Road</b>.</p>",
          "audioSrc": "",
          "videoSrc": ""
        }
      ]
    },
    {
      "cityId": 4,
      "cityName": "Leipzig",
      "cityImage": "/assets/city4/ECoD23_Leipzig_logo.png",
      "shortDesc": "Our Answer to a Crisis of Democracy? More Democracy!",
      "countryCode": "GER", // imporant! all capital letters
      "pdfSource": "https://capitalofdemocracy.eu/ecod-application-leipzig/",
      "abbr": "LEJ",
      "pages": [
        {
          "id": 1,
          "nextId": 2,
          "heading": "<h1>Leipzig - Facts (1/2)</h1>",
          "content": "<p class='ecod-city-detail-text' ><ul><li>Region: Saxony</li><li>Country: Germany</li><li>Population: 624,698</li><li>Official language: German</li><li>Website: <a target='_blank' href='https://english.leipzig.de/'>https://www.leipzig.de</a></li></ul></p>",
          "audioSrc": "",
          "videoSrc": ""
        },
        {
          "id": 2,
          "nextId": 3,
          "prevId": 1,
          "heading": "<h1>Leipzig - Facts (2/2)</h1>",
          "content": "<img class='ecod-city-image' src='/assets/city4/ECoD23_Leipzig_map1.jpg'> </img><img class='ecod-city-image' src='/assets/city4/ECoD23_Leipzig_map2.jpg'> </img>",
          "audioSrc": "",
          "videoSrc": ""
        },
        {
          "id": 3,
          "nextId": 4,
          "prevId": 2,
          "heading": "<h1>Leipzig - Video</h1><h2>Burkhard Jung (Social Democratic Party), Mayor of Leipzig</h2>",
          "content": "",
          "audioSrc": "",
          "videoSrc": "/assets/city4/23ECoD_Leipzig_video.mp4"
        },
        {
          "id": 4,
          "nextId": 5,
          "prevId": 3,
          "heading": "<h1>Leipzig - Summary (1/2)</h1><h2>Our Answer to a <i>Crisis of Democracy</i>? More Democracy!</h2>",
          "content": "<p class='ecod-city-detail-text'>As the <b>city of the <i>Peaceful Revolution</i></b> and of the <i>Workers and women’s rights movement</i>, Leipzig is shaped by an active civil society. Facing multiple crises and the growth of political and democratic tensions, populism, and sense of insecurity, <b>Leipzig feels the need to live and strengthen local democracy</b>. The biggest challenge for democracy is decreased interest in democracy by growing parts of the population. The city’s main task is to reverse this trend by <b>training democratic dialogue</b>, in order to enter into processes of negotiation with people of different opinions.</p>",
          "audioSrc": "",
          "videoSrc": ""
        },
        {
          "id": 5,
          "nextId": 6,
          "prevId": 4,
          "heading": "<h1>Leipzig - Summary (2/2)</h1><h2>Our Answer to a <i>Crisis of Democracy</i>? More Democracy!</h2>",
          "content": "<p class='ecod-city-detail-text'>As a European, cosmopolitan, and open city, our answer to a crisis of democracy is: More democracy! Leipzig's <b>successful transition to a democratic society</b> can serve as a model for other cities and regions. By displaying experiences and achievements while addressing the challenges Leipzig is facing, the <b>city contributes to strengthening democratic values, institutions, and practices throughout Europe</b>. It aims to <b>foster European integration and promote cultural diversity</b> and innovation.</p>",
          "audioSrc": "",
          "videoSrc": ""
        },
        {
          "id": 6,
          "nextId": 7,
          "prevId": 5,
          "heading": "<h1>Leipzig - Project A (1/2)</h1><h2>Leipzig Youth Parliament</h2>",
          "content": "<p class='ecod-city-detail-text' >The <b>Leipzig Youth Parliament / Jugendparlament Leipzig</b> (JuPa), is a representative <b>platform for young city-residents to participate in local decision-making</b> processes. It is <b>designed to engage youth</b>, typically between the ages of 14 and 21, in shaping policies and initiatives that directly affect them and their community. JuPa consists of 20 young representatives who are elected by young people in Leipzig. They can join committees and groups, participate in local politics, and join the debate.</p>",
          "audioSrc": "",
          "videoSrc": ""
        },
        {
          "id": 7,
          "nextId": 8,
          "prevId": 6,
          "heading": "<h1>Leipzig - Project A (2/2)</h1><h2>Leipzig Youth Parliament</h2>",
          "content": "<p class='ecod-city-detail-text' ><b>JuPa works together with local authorities, policymakers, and organisations</b> to provide recommendations, input, and feedback on relevant topics. This fosters a <b>stronger connection between youth and local government</b>. JuPa successfully initiated a range of council resolutions addressing issues such as complaint-management systems in accommodation facilities for asylum seekers, support for young people in stationary youth facilities, construction of bike lanes, or declaring a state of climate emergency.</p>",
          "audioSrc": "",
          "videoSrc": ""
        },
        {
          "id": 8,
          "nextId": 9,
          "prevId": 7,
          "heading": "<h1>Leipzig - Project B (1/3)</h1><h2>Colourful Mockau Summer</h2>",
          "content": "<p class='ecod-city-detail-text' >In Mockau, a neighbourhood with many challenges, the <b>community co-created a cross-generational and cross-cultural meeting point</b>. Here, the lack of local cultural offers is addressed, in collaboration with the district network INTIGRA. Planning and organising the <b>Colourful Mockau Summer festival</b>, with a growing network of inhabitants and neighbourhood initiatives, supported the development of local social-cultural offers. Temporary festival events will grow into long-term initiatives within the district.</p>",
          "audioSrc": "",
          "videoSrc": ""
        },
        {
          "id": 9,
          "nextId": 10,
          "prevId": 8,
          "heading": "<h1>Leipzig - Project B (2/3)</h1><h2>Colourful Mockau Summer</h2>",
          "content": "<p class='ecod-city-detail-text' ><b>The programme provides cultural and artistic events</b> (live music, exhibitions, workshops), <b>community strengthening and district-identity building</b> (community breakfast, district rally), <b>events across schools</b> in the district (football tournament, creative offers, political education), as well as <b>information on relevant topics</b> (migration, district development).</p>",
          "audioSrc": "",
          "videoSrc": ""
        },
        {
          "id": 10,
          "nextId": 11,
          "prevId": 9,
          "heading": "<h1>Leipzig - Project B (3/3)</h1><h2>Colourful Mockau Summer</h2>",
          "content": "<p class='ecod-city-detail-text' >After three years, acceptance has grown and <b>the festival serves as a beacon for community work</b>. People of the neighbourhood, who work closely with the city administration on a very low budget, manage to bring very diverse backgrounds together. Thus they create a <b>basis for further communication, engagements, and project planning</b>.</p>",
          "audioSrc": "",
          "videoSrc": ""
        },
        {
          "id": 11,
          "nextId": 12,
          "prevId": 10,
          "heading": "<h1>Leipzig - Project idea A (1/3)</h1><h2>Strategic Blackout Planning</h2>",
          "content": "<p class='ecod-city-detail-text' >The challenge we face in a blackout is the <b>preservation of a free, democratic, and socially fair urban society while experiencing dramatic restrictions of basic services</b>. Established control mechanisms for distributing reduced resources are missing, due to <b>lack of communication and information</b>. In addition, access to vital resources and services is impaired or impossible.</p>",
          "audioSrc": "",
          "videoSrc": ""
        },
        {
          "id": 12,
          "nextId": 13,
          "prevId": 11,
          "heading": "<h1>Leipzig - Project idea A (2/3)</h1><h2>Strategic Blackout Planning</h2>",
          "content": "<p class='ecod-city-detail-text' >Leipzig is developing a <b>new approach to meet these challenges, avoiding rushed top-down decisions</b>. Citizens are prepared to be a part of the process so that they have a <b>sense of collective responsibility and knowledge about possible actions</b> in a crisis. This project is connected with community, empowerment, and participation. The <b>city acts as moderator and provides guidance</b> for the basic management and implementation.</p>",
          "audioSrc": "",
          "videoSrc": ""
        },
        {
          "id": 13,
          "nextId": 14,
          "prevId": 12,
          "heading": "<h1>Leipzig - Project idea A (3/3)</h1><h2>Strategic Blackout Planning</h2>",
          "content": "<p class='ecod-city-detail-text' >By developing <b>structures and plans on how to distribute resources</b> based on a multilevel system, citizens can decide how to prepare and implement ideas. The city acts as an advisor and offers infrastructure and the municipal framework planning. Moderated joint-planning <b>prevents feeling helpless when facing a crisis</b>, and encourages solidarity and joint action.</p>",
          "audioSrc": "",
          "videoSrc": ""
        },
        {
          "id": 14,
          "nextId": 15,
          "prevId": 13,
          "heading": "<h1>Leipzig - Project idea B (1/3)</h1><h2>Smart City Lab</h2>",
          "content": "<p class='ecod-city-detail-text' >The <b>Smart City Lab</b> will be embedded within an existing community of actors from the city administration, science, civil society, and economy. They are currently involved in Leipzig’s digital transformation, and the Lab will be their new home. An <b>open space that invites citizens to learn about digital participation and helps them navigate the digital sphere</b>.</p>",
          "audioSrc": "",
        },
        {
          "id": 15,
          "nextId": 16,
          "prevId": 14,
          "heading": "<h1>Leipzig - Project idea B (2/3)</h1><h2>Smart City Lab</h2>",
          "content": "<p class='ecod-city-detail-text' >Many people still lack the necessary digital skills and knowledge to master digital technologies. <b>Training includes basic computer-literacy, understanding online safety and privacy</b>, using digital tools for communication and collaboration, and being able to <b>critically evaluate online information</b>. </p>",
          "audioSrc": "",
          "videoSrc": ""
        },
        {
          "id": 16,
          "prevId": 15,
          "heading": "<h1>Leipzig - Project idea B (3/3)</h1><h2>Smart City Lab</h2>",
          "content": "<p class='ecod-city-detail-text' >As citizens engage more with digital services and platforms, there are increased risks related to privacy and security. As technology advances, citizens face ethical dilemmas and legal challenges associated with its use. <b>Leipzig’s Smart City Lab programme helps people gain security, recognise their self-efficacy, and acquire skills</b> to deal with digital solutions. <b>Smart City Lab will be an ecosystem for a diverse urban-community</b> to work together on beneficial solutions for Leipzig.</p>",
          "audioSrc": "",
          "videoSrc": ""
        }
      ]
    },
    {
      "cityId": 5,
      "cityName": "Vienna",
      "cityImage": "/assets/city5/ECoD23_Vienna_logo.png",
      "shortDesc": "The Viennese Way: Empowered Democracy in the Most Liveable City",
      "countryCode": "AUT", // imporant! all capital letters
      "pdfSource": "https://capitalofdemocracy.eu/ecod-application-vienna/",
      "abbr": "LEJ",
      "pages": [
        {
          "id": 1,
          "nextId": 2,
          "heading": "<h1>Vienna - Facts (1/2)</h1>",
          "content": "<p class='ecod-city-detail-text' ><ul><li>Region: Vienna</li><li>Country: Austria</li><li>Population: 2,000,000</li><li>Official language: Austrian</li><li>Website: <a target='_blank' href='https://www.wien.gv.at/english/'>https://www.wien.gv.at</a></li></ul></p>",
          "audioSrc": "",
          "videoSrc": ""
        },
        {
          "id": 2,
          "nextId": 3,
          "prevId": 1,
          "heading": "<h1>Vienna - Facts (2/2)</h1>",
          "content": "<img class='ecod-city-image' src='/assets/city5/ECoD23_Vienna_map1.jpg'> </img><img class='ecod-city-image' src='/assets/city5/ECoD23_Vienna_map2.jpg'> </img>",
          "audioSrc": "",
          "videoSrc": ""
        },
        {
          "id": 3,
          "nextId": 4,
          "prevId": 2,
          "heading": "<h1>Vienna - Video</h1><h2>Michael Ludwig (Social Democratic Party), Mayor of Vienna</h2>",
          "content": "",
          "audioSrc": "",
          "videoSrc": "/assets/city5/23ECoD_Vienna_video.mp4"
        },
        {
          "id": 4,
          "nextId": 5,
          "prevId": 3,
          "heading": "<h1>Vienna - Summary (1/2)</h1><h2>The Viennese Way: Empowered Democracy in the Most Liveable City</h2>",
          "content": "<p class='ecod-city-detail-text'>Together, we want to create a <b>participatory, social, and climate-just city</b>. Simply more democracy, by striving for more co-creation, participation, and deliberation. Our goal is to <b>make the most-liveable city also the most democratic</b>. To do this, we need <b>decision-makers who are willing to share power and residents who are willing to take responsibility</b>. Sustainable solutions need to incorporate the experiences and needs of the people. <b>Direct participation must be made possible</b>.</p>",
          "audioSrc": "",
          "videoSrc": ""
        },
        {
          "id": 5,
          "nextId": 6,
          "prevId": 4,
          "heading": "<h1>Vienna - Summary (2/2)</h1><h2>The Viennese Way: Empowered Democracy in the Most Liveable City</h2>",
          "content": "<p class='ecod-city-detail-text'>The multiple crises of our time present us with challenges for democratic politics, both as a traditional city administration and as a pluralistic community. Previous approaches are no longer sufficient, and <b>solutions for the future call for new ideas that complement proven methods</b>. Vienna’s task is to enable more participation, to balance different interests, and to <b>actively involve those who do not or rarely engage</b>. We can only change and empower democracy together, building on the tradition of a successful city administration. We <b>open up top-down urban design</b> and integrate residents’ issues, ideas, and wish to become more involved.</p>",
          "audioSrc": "",
          "videoSrc": ""
        },
        {
          "id": 6,
          "nextId": 7,
          "prevId": 5,
          "heading": "<h1>Vienna - Project A (1/2)</h1><h2>Participative Children and Youth Million</h2>",
          "content": "<p class='ecod-city-detail-text' >Appropriate <b>participation of children and young people</b>, which is part of the Austrian Federal Constitution’s Convention on the Rights of the Child, means they must be heard on all matters affecting them and their opinions must be taken into account. The “Participative Children and Youth Million” is one of 193 measures from the <b>Children and Youth Strategy, collecting ideas for projects via an online platform</b>. These ideas are <b>implemented using a fund of one million euros from the city budget</b>.</p>",
          "audioSrc": "",
          "videoSrc": ""
        },
        {
          "id": 7,
          "nextId": 8,
          "prevId": 6,
          "heading": "<h1>Vienna - Project A (2/2)</h1><h2>Participative Children and Youth Million</h2>",
          "content": "<p class='ecod-city-detail-text' >In the pilot year, around <b>250 ideas were submitted and reviewed</b>. They were discussed in co-creation workshops with the young idea-submitters, and then <b>revised and shaped into implementable projects</b>. Vienna's <b>children and young people had the opportunity to vote on these projects</b> using an online voting-system. This resulted in 20 winning projects, confirmed by the Children's and Youth Parliament. They will now be implemented by the responsible departments of the City of Vienna with the continued participation of the idea submitters.</p>",
          "audioSrc": "",
          "videoSrc": ""
        },
        {
          "id": 8,
          "nextId": 9,
          "prevId": 7,
          "heading": "<h1>Vienna - Project B (1/2)</h1><h2>Kulturlabor Gemeindebau</h2>",
          "content": "<p class='ecod-city-detail-text' ><b>Culture Lab Municipal Housing / Kulturlabor Gemeindebau promotes a participation-oriented, interdisciplinary cultural practice</b> in Vienna's municipal buildings. The formats are intended to strengthen the creative activity of the residents. For example, art projects are developed in dialogue between residents and artists. This method of production <b>encourages residents to become actively creative in the cultural space of municipal housing</b>.</p>",
          "audioSrc": "",
          "videoSrc": ""
        },
        {
          "id": 9,
          "nextId": 10,
          "prevId": 8,
          "heading": "<h1>Vienna - Project B (2/2)</h1><h2>Kulturlabor Gemeindebau</h2>",
          "content": "<p class='ecod-city-detail-text' >Sharing experiences of different art projects, the (semi-public) <b>cultural space of municipal buildings is opened up and made tangible</b>, not only for residents of the municipal buildings, but for all culturally-interested people in Vienna. In this way, this work <b>contributes to cultural participation and creativity development</b>. Art sharpens the senses and refines the process of perception, contributing to cultural education. Through these experiences and confrontations with art, people are prepared for the <b>debate about cultural values and norms in society</b>.</p>",
          "audioSrc": "",
          "videoSrc": ""
        },
        {
          "id": 10,
          "nextId": 11,
          "prevId": 9,
          "heading": "<h1>Vienna - Project idea A (1/2)</h1><h2>Democracy Lab</h2>",
          "content": "<p class='ecod-city-detail-text' >Voting, the most important form of participation, may seem ineffective to some. Many are simply excluded from it these days because they lack Austrian citizenship. <b>In Vienna, over 30% of the population is not allowed to vote</b> – a number that continues to rise due to immigration. However, even <b>with innovative participation processes, the challenge remains to involve and reach those who are inactive</b>. The city also needs to hear the voices of the quiet ones, those who cannot and do not want to express themselves politically. </p>",
          "audioSrc": "",
          "videoSrc": ""
        },
        {
          "id": 11,
          "nextId": 12,
          "prevId": 10,
          "heading": "<h1>Vienna - Project idea A (2/2)</h1><h2>Democracy Lab</h2>",
          "content": "<p class='ecod-city-detail-text' >The establishment of a <b>Democracy Lab addresses the crisis of representative democracy by creating a physical space for empowerment, innovation, and experimentation</b>. This low-threshold and open offer enables all Viennese residents to participate in the shaping and development of democracy. Together, <b>solutions are developed through co-creation, participation, and deliberation</b>. It is a safe space for developing and testing ideas, new approaches, and methods of participation, to <b>plan and implement further activities</b>. </p>",
          "audioSrc": "",
          "videoSrc": ""
        },
        {
          "id": 12,
          "nextId": 13,
          "prevId": 11,
          "heading": "<h1>Vienna - Project idea B (1/3)</h1><h2>Vienna Climate Team</h2>",
          "content": "<p class='ecod-city-detail-text' >The systemic problem of climate change poses a significant challenge for traditional representative democracies and administrations. <b>Climate-related measures often entail politically-sensitive decisions</b>. To effectively address these challenges, <b>new forms of decision-making as well as new processes are required</b>. They bring the administrative level in <b>dialogue with the public to increase acceptance</b>.</p>",
          "audioSrc": "",
        },
        {
          "id": 13,
          "nextId": 14,
          "prevId": 12,
          "heading": "<h1>Vienna - Project idea B (2/3)</h1><h2>Vienna Climate Team</h2>",
          "content": "<p class='ecod-city-detail-text' ><b>Vienna Climate Team / Wiener Klimateam</b> is an innovative participation project that <b>implements projects proposed by and decided upon by citizens</b>. Piloting in 2022 and 2023, three different Viennese municipal districts each year began implementing projects with a budget of 20 euros per resident. The project followed a <b>one-year participation cycle</b> with six phases. It implemented target-group specific and cross-media participation elements to <b>engage as many and as diverse population groups as possible</b>.</p>",
          "audioSrc": "",
          "videoSrc": ""
        },
        {
          "id": 14,
          "prevId": 13,
          "heading": "<h1>Vienna - Project idea B (3/3)</h1><h2>Vienna Climate Team</h2>",
          "content": "<p class='ecod-city-detail-text' ><b>Local outreach activities occurred in public spaces</b>, focusing on the <b>hard-to-reach target groups</b>. Co-creation workshops were conducted to develop project outlines. Finally, a representative jury of randomly-selected <b>citizens chose the winning projects</b>. Based on these experiences, the <b>project will be standardised in the administrative processes</b>.</p>",
          "audioSrc": "",
          "videoSrc": ""
        }
      ]
    }
  ]
}
export default cityJson;
