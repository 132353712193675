import './App.css';
import {BrowserRouter} from 'react-router-dom';

import AvailableRoutes from './components/routing/AvailableRoutes'

//import pages below
import TopAppBar from "./components/TopAppBar";


//import states below
import UserState from "./context/user/UserState";
import UpdateUserPageTime from "./utils/updateUserPageTime";


function App() {

    return (
        <div className="App">
            <BrowserRouter>
                <UserState>
                    <UpdateUserPageTime/>
              <AvailableRoutes/>
                </UserState>
            </BrowserRouter>
        </div>
);
}

export default App;
