import React from "react";
import {styled} from '@mui/material/styles';
import {Container, CssBaseline} from "@mui/material";
import Box from "@mui/material/Box";

const styles = {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    height: "100vh",
    background: "#dedede",
    boxSizing: "border-box"
};

const PageSize = styled('div')(({theme}) => ({
    //padding: theme.spacing(1),
    //justifyContent: 'center',
    margin: 0,
    padding: 0,
    [theme.breakpoints.down('md')]: {
        width: '100%',
        height: '100%'
    },
    [theme.breakpoints.up('md')]: {
        width: '50%',
        height: '50%'
    }
}));

const Page = (props) => {

    return (<> <CssBaseline/>
        <Container fixed sx={{}}>
            <Box className={"ecod-c-wrapper"}
                sx={{ padding:"2%",height:"100%", display:"flex", alignItems:"center", justifyContent:"center", flexDirection:"column"}}> {props.children}</Box>
        </Container></>)


}
export default Page;