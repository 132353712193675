import React, {useContext, useEffect, useState} from "react";
import UserContext from "../../context/user/userContext";
import {useLocation, useNavigate, useSearchParams} from "react-router-dom";
import Page from '../Page'
import {Button, CircularProgress, Dialog, DialogContent, DialogTitle, Grid, IconButton} from "@mui/material";

import CityCard from "../layout/CityCard";
import {styled} from '@mui/material/styles';
import Typography from "@mui/material/Typography";
import CityVoteOverview from "../layout/CityVoteOverview";
import Layout from "../Layout";
import Box from "@mui/material/Box";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import * as PropTypes from "prop-types";
import {DEADLINE_VOTING} from "../../context/types";

const BootstrapDialog = styled(Dialog)(({theme}) => ({
    '& .MuiPaper-root': {
        borderRadius: "20px",
        width: "100%"
    },
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

function BootstrapDialogTitle(props) {
    const {children, onClose, ...other} = props;

    return (
        <DialogTitle sx={{m: 0, p: 2}} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseOutlinedIcon/>
                </IconButton>
            ) : null}
        </DialogTitle>
    );
}


BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};


const Home = () => {
    const [open, setOpen] = React.useState(false);
    const userContext = useContext(UserContext)
    const {
        loadUserMeta,
        isAuthenticated,
        loading,
        userMeta,
        cities,
        setUserAction,
        userVotings,
        loadUserVotings,
        setFinalUserVoting,
        cityUserMetaContext
    } = userContext
    const [searchParams, setSearchParams] = useSearchParams();
    const location = useLocation();

    const [votableCities, setVoteableCities] = useState(5);

    const {overall_progress_city, overall_progress_step} = userMeta;

    const [openConfirmation, setOpenConfirmation] = useState(false);

    const editRating = () => {
        const city = cities.find(city => city.countryCode !== userMeta.country);
        if (city) {
            navigate(`/vote/${city.cityId}/?uuid=${searchParams.get('uuid')}`, {state: {"city": city}})
            loadUserVotings();
        }
    }

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleConfirmationClose = () => {
        setOpenConfirmation(false);
        window.location.reload(false);
    }

    const submitVoting = () => {
        setFinalUserVoting();
        setOpen(false);
        setOpenConfirmation(true)
    }

    const handleClose = () => {
        setOpen(false);

    };

    const navigate = useNavigate();


    useEffect(() => {
        if (userMeta.opened_app === null && location.state == null)  {
            setUserAction("opened_app");
            navigate(`/tutorial/1/?uuid=${searchParams.get('uuid')}`)
        }
        loadUserMeta();
        if (!loading) {
            const userCanVote = cities.filter(city => city.countryCode !== userMeta.country)
            setVoteableCities(userCanVote.length)
        }

    }, [loadUserMeta, navigate, searchParams, setUserAction, userMeta.opened_app,userMeta.has_started, loading, isAuthenticated])

    const startVoting = () => {

        if (overall_progress_city && overall_progress_step) {
            const city = cities.find(city => city.cityId == userMeta.overall_progress_city)

            navigate(`/city-detail/${userMeta.overall_progress_city}/${userMeta.overall_progress_step}?uuid=${searchParams.get('uuid')}`, {
                state: {
                    city: city,
                }
            });
        } else {
            navigate(`/city-detail/1/1?uuid=${searchParams.get('uuid')}`, {
                state: {
                    city: cities[0],
                }
            });
        }

    }


    return (

        (loading || !isAuthenticated) ?
            <>
              <CircularProgress></CircularProgress>
            </>
            :
            <Layout>
                <>
                    <Page>
                        <Grid container rowSpacing={3} alignContent={"flex-start"} mt={2}>
                            <Grid item sm={12} md={12} lg={6} xl={6}>
                                <Typography variant={"h1"} align={"left"}>Overview Cities</Typography>
                            </Grid>
                            <Grid item sm={12} md={12} lg={6} xl={6}>
                                <Grid container rowSpacing={3}>
                                    <CityCard cities={cityUserMetaContext}></CityCard>
                                    {votableCities === userVotings.length && !userMeta.finished_voting &&
                                        <Grid container rowSpacing={3}>
                                            <Grid item xs={12} lg={12}>
                                                <BootstrapDialog
                                                    onClose={handleClose}
                                                    aria-labelledby="customized-dialog-title"
                                                    open={open}
                                                ><DialogContent dividers>
                                                    <BootstrapDialogTitle id="customized-dialog-title"
                                                                          onClose={handleClose}>
                                                    </BootstrapDialogTitle>
                                                    <Typography mb={4} variant="h5" textAlign={"center"}>
                                                        Overview Rating
                                                    </Typography>
                                                    <Grid container rowSpacing={1.5} alignItems={"center"} justifyContent={"center"} direction={"column"} mb={2}>
                                                        {cityUserMetaContext.map((city, key) => {
                                                            if (city.countryCode !== userMeta.country) {
                                                                return <CityVoteOverview key={key} city={city}
                                                                                         votings={userVotings}></CityVoteOverview>

                                                            }
                                                        })}
                                                        <Grid item width={{xs: "100%", lg: "60%"}} marginTop={"4%"}>
                                                            <Button className={"button-secondary"} fullWidth={true}
                                                                    variant={'outlined'}
                                                                    onClick={editRating}>
                                                                Edit Rating
                                                            </Button>
                                                        </Grid>
                                                        <Grid item width={{xs: "100%", lg: "60%"}}> 
                                                            <Button className={"button-primary"} fullWidth={true}
                                                                    variant={'contained'}
                                                                    onClick={submitVoting}>
                                                                Send
                                                            </Button>
                                                        </Grid>
                                                    </Grid>

                                                </DialogContent>
                                                </BootstrapDialog>
                                            </Grid>
                                        </Grid>
                                    }
                                    {userMeta.finished_voting &&
                                        /*<Grid container rowSpacing={3} flexDirection={"column"}>*/
                                        <Grid item xs={12} md={12} lg={12}>
                                            <p> Thank you for your service for democracy! Your rating has been received.</p>
                                        </Grid>
                                        /*    </Grid>*/
                                    }
                                </Grid>
                                {!userMeta.finished_voting && votableCities > userVotings.length &&
                                    <Box marginTop={"20px"}>
                                        <Button className="button-primary" color={"primary"} fullWidth={true}
                                                variant={"contained"}
                                                onClick={() => startVoting()}>
                                            {overall_progress_step && overall_progress_city ? "Continue" : "Start"}
                                        </Button>
                                    </Box>
                                }
                                {votableCities === userVotings.length && !userMeta.finished_voting && DEADLINE_VOTING >= Date.now() &&
                                    <Box mt={2}>
                                        <Button fullWidth={true} className={"button-primary"} variant={"contained"}
                                                onClick={handleClickOpen}> Save Rating </Button>
                                    </Box>
                                }
                            </Grid>
                            {/*         {cities.map((city, key) => {
                            return <CityCard key={key} city={city}></CityCard>
                        })}*/}
                        </Grid>


                    </Page>
                </>

                <BootstrapDialog
                    onClose={handleClose}
                    aria-labelledby="customized-dialog-title"
                    open={openConfirmation}
                ><DialogContent dividers>
                    <BootstrapDialogTitle id="customized-dialog-title" onClose={handleConfirmationClose}>
                    </BootstrapDialogTitle>
                    <Typography mb={3} mt={3} p={2} variant="h5" textAlign={"center"}>
                        Thank you for your service for democracy! Your rating has been received.
                    </Typography>
                    <Grid container rowSpacing={1} justifyContent={"center"}>

                        <Grid item xs={12} md={12} lg={6} xl={6} justifyContent={"center"} alignItems={"center"}>
                            <Button className={"button-secondary"} fullWidth={true} variant={'outlined'}
                                    onClick={handleConfirmationClose}>
                                Close
                            </Button>
                        </Grid>
                    </Grid>

                </DialogContent>
                </BootstrapDialog>
            </Layout>

    );

}


export default Home
