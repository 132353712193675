import React from 'react'
import {Divider, Grid, Stack} from "@mui/material";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {useNavigate, useSearchParams} from "react-router-dom";
import StarIcon from "@mui/icons-material/Star";
import StarRoundedIcon from '@mui/icons-material/StarRateRounded';

const CityVoteOverview = props => {

    const {city, votings} = props;
    const {cityName, cityImage, shortDesc, cityId} = city;

    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();

    const currentCity = votings.find(item => item.city == cityId);

    const navigateVote = () => {
        const uuid = searchParams.get('uuid')
        navigate(`/vote/${cityId}?uuid=${uuid}`)

    }

    return (
        <>
            <Grid container spacing={1} mt={1} mb={1} justifyContent={"space-between"} alignItems={"center"} paddingLeft={"4%"} paddingRight={"4%"}>
                <Grid item xs={7} md={8} lg={8} display={"flex"} flexDirection={"row"} alignItems={"center"} columnSpacing={2}>
                    <Grid item xs={4} md={4} lg={4}>
                        <Typography component="div" color={"action"} variant="h3">
                            {cityName}
                        </Typography>
                    </Grid>
                    <Grid item xs={3} lg={4} md={4} paddingLeft={"2.5rem"}>
                        <Typography variant={"subtitle2"}>
                            ({city.countryCode})
                        </Typography>
                    </Grid>
                </Grid>
                <Grid item xs={3} lg={4} md={4} xl={4} justifyContent={"flex-end"} position={"absolute"} right={"8%"}>
                    <Stack direction="row" alignItems="flex-end" justifyContent={"flex-end"}>
                        <StarRoundedIcon sx={{verticalAlign: {xs: "top", lg: "top"}, marginBottom: "0.2rem", fontSize: {xs: "1rem", lg: "1.375rem"}}}>PDF</StarRoundedIcon>
                        <Typography fontSize={{xs: "1rem", lg: "1.375rem"}}
                                    padding={"0"}>{currentCity.rating}</Typography>
                    </Stack>

                </Grid>
                <Box sx={{display: 'flex', alignItems: 'center', pl: 1, pb: 1}}>
                </Box>
            </Grid>

            <Divider sx={{width: "100%"}}></Divider>
        </>
    )

}

export default CityVoteOverview;