import React from "react";
import TopAppBar from "./TopAppBar";
import {createTheme, ThemeProvider} from '@mui/material/styles';


let theme = createTheme({
    palette: {
        primary: {
            main: '#009FE3',
        },
        secondary: {
            main: '#004F9E',
        }, action: {
            main: '#182A3B'
        },
        accent: {
            main: '#929292'
        }
    },
    button: {
        primary: {
            main: '#009FE3',
            borderRadius: "20px"
        },
        divider: {
            primary: {
                main: '#929292'
            }
        }
    },
    typography: {
        fontFamily: [
            'Segoe UI'
        ],
        subtitle2: {
            fontSize: "0.6rem",
            color: "#929292",
            alignText: "left"
        },
        body2: {
            fontSize: "0.875rem",
            lineHeight: "1rem",
            color: "#004F9E",
            alignText: "left"
        },
        button: {
            textTransform: "none",
            alignText: "left"
        },
        body1: {
            color: "#929292",
            alignText: "left"
        },
        h1: {
            color: "#004F9E",
            fontWeight: "bold",
            alignText: "left"
        },
        h3: {
            color: "#182A3B",
            fontSize: "1.2rem",
            alignText: "left"
        },
        h2: {
            color: "#182A3B",
            fontSize: "1.25rem",
            lineHeight: "1.688",
            fontWeight: "bold",
            alignText: "left",
        },
        h4: {
            color: "#182A3B",
            alignText: "left"
        },
        h5: {
            color: "#182A3B",
            fontWeight: 500
        },
        p: {
            alignText: "left",
            color: "#3D3D3D",
        }
    },
    shape: {
        pillRadius: 50
    },
    overrides: {
        MuiButton: {
            background: '#009FE3',
            borderRadius: 20,
            color: "white",
            contained: {
                '@media (hover: none)': {
                    '&:hover': {
                        backgroundColor: ""
                    }
                }
            }
        },

    },
    components: {
        MuiAppBar: {
            styleOverrides: {
                elevation: 0,
                colorPrimary: {
                    backgroundColor: "white"
                }
            }
        },
        MuiCardContent: {
            root: {
                padding: 0,
                "&:last-child": {
                    paddingBottom: 0,
                },
            },
        },
    }
});

theme = createTheme(theme, {
    palette: {
        info: {
            main: theme.palette.secondary.main,
        },
    },
    typography: {
        h6: {
            fontWeight: 600,
            [theme.breakpoints.down('sm')]: {
                fontSize: "1.125rem",
            },
            [theme.breakpoints.up('sm')]: {
                fontSize: "1.375rem",
            },
            [theme.breakpoints.up('lg')]: {
                fontSize: "1.688rem",
            },
        },
        h5: {
            [theme.breakpoints.down('lg')]: {
                fontSize: "1.3",
                lineHeight: "1.7rem",
            },
            [theme.breakpoints.up('lg')]: {
                fontSize: "1.7rem",
                lineHeight: "2.1rem",
            }
        },
        h1: {
            [theme.breakpoints.down('lg')]: {
                fontSize: "1.75rem",
                lineHeight: "2.313rem",
            },
            [theme.breakpoints.up('lg')]: {
                fontSize: "5.25rem",
                lineHeight: "6rem",
            }
        },
        subtitle2: {
            [theme.breakpoints.down('sm')]: {
                fontSize: "0.75rem",
                lineHeight: "0.875rem",
            },
            [theme.breakpoints.up('sm')]: {
                fontSize: "1.125rem",
                lineHeight: "1.375rem",
            },
            [theme.breakpoints.up('lg')]: {
                fontSize: "1rem",
                lineHeight: "1.375rem",
            }
        },
        p: {
            [theme.breakpoints.down('lg')]: {
                fontSize: "1rem",
                lineHeight: "1.5rem"
            },
            [theme.breakpoints.up('lg')]: {
                fontSize: "1.2rem",
            }
        }
    }
});


const Layout = (props) => {

    return (
        <>
            <ThemeProvider theme={theme}>
                <TopAppBar data={props}></TopAppBar>
                {props.children}
            </ThemeProvider>
        </>
    )

}

export default Layout;
