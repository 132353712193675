const additionalFeedbackJson = [
    {"name": "complex", "id":0, "value":"1","variant":"outlined","color":"#929292"},
    {"name": "irrelevant", "id":1, "value":"2","variant":"outlined","color":"#929292"},
    {"name": "oversized", "id":2, "value":"3","variant":"outlined","color":"#929292"},
    {"name": "nothing new", "id":3, "value":"4","variant":"outlined","color":"#929292"},
    {"name": "too local", "id":4, "value":"5","variant":"outlined","color":"#929292"},
]

export default additionalFeedbackJson


