import React, {useContext, useEffect} from "react";
import UserContext from "../../context/user/userContext";
import { useSearchParams } from "react-router-dom";
import Page from '../Page'


const NotAuthorized = (props) =>{


    return(
        <Page>
           <p> Please use the link provided in your email to access this page.<br/> If you think this is an error, please contact us at <a href="mailto:office@capitalofdemocracy.eu">office@capitalofdemocracy.eu</a>.</p>
        </Page>
    )

}

export default NotAuthorized