import React from "react";
import PropTypes from 'prop-types';
import LinearProgress from '@mui/material/LinearProgress';
import Box from '@mui/material/Box';


const LinearProgressWithLabel = ({position, total}) => {
    return(
        <LinearProgress color="accent" variant="determinate" className={"test-im-rendered"} value={Math.round(
            position / total * 100
        )}
        />
    )

    return (
        <Box sx={{display: 'flex', alignItems: 'center'}} >
            <Box sx={{width: '100%'}}>
                <LinearProgress variant="determinate" value={Math.round(
                    position / total * 100
                )} color='secondary'
                />
            </Box>
            {/*<Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="text.secondary">{`${Math.round(
          position/total * 100
        )}%`}</Typography>
        </Box>*/}
        </Box>
    );
}

LinearProgressWithLabel.propTypes = {
    position: PropTypes.number.isRequired,
    total: PropTypes.number.isRequired,
};

export default LinearProgressWithLabel;