import React from "react";
import {Box} from "@mui/material";
import styled from "@emotion/styled";

const AdjustingButtonBox = styled(Box)(({theme}) => ({
    textAlign: "center",
    alignItems: "center",
    justifyContent: "center",
    paddingTop: "2%",
    paddingBottom: "2%",
    [theme.breakpoints.down('lg')]: {
        width: "100%"
    },
    [theme.breakpoints.up('lg')]: {
        width: "30vw",
        paddingTop: 20,
        paddingLeft: 15,
        paddingRight: 15,
    }
}));

export default AdjustingButtonBox;