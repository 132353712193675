import React from "react";
import {Grid} from "@mui/material";
import styled from "@emotion/styled";

const ContentBox = styled(Grid)(({theme}) => ({
    container: true,
    overflow: "auto",
    alignItems: "center",
    justifyContent: "center",
    mb: "auto",
    paddingLeft: "1.8rem",
    paddingRight: "1.8rem",
    paddingBottom: "2%",
    [theme.breakpoints.down('lg')]: {
        flexDirection: "column",
        paddingBottom: "40%",
        paddingTop: "20px",
        scrollPaddingTop: "20px",
        top:"20px",
        width: "100%"
    },
    /*[('@media (min-height:700px and max-width:700px)')]:{
        maxHeight: window.innerHeight*0.75,
    },
    [('@media (max-height:650px and max-width:700px)')]:{
        maxHeight: window.innerHeight*0.7
    },
    [('@media (max-height:600px and max-width:700px)')]:{
        maxHeight: window.innerHeight*0.6
    },
*/
/*    [theme.breakpoints.between('xs','sm')]:{
        maxHeight: window.innerHeight*0.6,
    },*/
    [theme.breakpoints.up('lg')]: {
        marginTop: 0,
        maxHeight: "70vh",
        flexDirection: "row-reverse",
        maxWidth: "50vw",
        minWidth: "45vw",
        paddingBottom: "8%"
    }
}))

export default ContentBox;


