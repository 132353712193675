import React, {useContext, useEffect} from 'react';
import UserContext from "../context/user/userContext";



const UpdateUserPageTime = () =>{

    const userContext = useContext(UserContext)
    const {updateUserPageTime} = userContext


    useEffect(() => {
        const intervalCall = setInterval(() => {
            updateUserPageTime();
        }, 60000);
        return () => {
            // clean up
            clearInterval(intervalCall);
        };
    }, []);


}



export default UpdateUserPageTime;