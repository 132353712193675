import React from "react";
import {Grid} from "@mui/material";
import styled from "@emotion/styled";

const AdjustingButtonGrid = styled(Grid)(({theme}) => ({
    container: true,
    alignItems: "flex-end",
    justifyContent: "center",
    mb: 3,
    mt:2,
    display: "flex",
    [theme.breakpoints.down('lg')]: {
        flexDirection: "column",
        spacing: 2,
        columns: "auto",
        width: "100%",
        marginLeft: "4%",
        marginRight: "4%",
        marginTop: "1%"
    },
    [theme.breakpoints.up('lg')]: {
        flexDirection: "row",
        justifyContent: "flex-end",
        spacing: 3,
        columns: "auto",
    }
}));

export default AdjustingButtonGrid;