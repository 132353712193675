import React, {useContext, useEffect} from "react";
import {Route, Routes} from "react-router-dom";
import PrivateRoute from "./PrivateRoute";
import Home from "../pages/Home";
import CityDetail from "../pages/CityDetail";
import NotAuthorized from "../pages/NotAuthorized";
import UserContext from "../../context/user/userContext";
import Vote from '../pages/Vote'
import Tutorial from "../pages/Tutorial";
import ContactUs from "../pages/ContactUs";
const AvailableRoutes = (props) => {
    const userContext = useContext(UserContext)
    const {loadUserMeta,isAuthenticated,loading } = userContext

    useEffect(() => {
        loadUserMeta()
    }, [loadUserMeta])


    return (
        <Routes>
            {/*<Route exact path='/' element={<Home/>}></Route>*/}
            <Route
                path="/"
                element={
                    <PrivateRoute auth={{ isAuthenticated: isAuthenticated,loading:loading }}>
                        <Home/>
                    </PrivateRoute>
                }
            />

            <Route
                path="/city-detail/:cityId/:cityStep"
                element={
                    <PrivateRoute auth={{ isAuthenticated: isAuthenticated,loading:loading }}>
                        <CityDetail />
                    </PrivateRoute>
                }
            />
            <Route
                path="/tutorial/:tutorialStep"
                element={
                    <PrivateRoute auth={{ isAuthenticated: isAuthenticated,loading:loading }}>
                        <Tutorial/>
                    </PrivateRoute>
                }
            />
            <Route
                path="/vote/:cityId"
                element={
                    <PrivateRoute auth={{ isAuthenticated: isAuthenticated,loading:loading }}>
                        <Vote/>
                    </PrivateRoute>
                }
            />

            <Route
                path="/contact-us"
                element={
                    <PrivateRoute auth={{ isAuthenticated: isAuthenticated,loading:loading }}>
                       <ContactUs></ContactUs>
                    </PrivateRoute>
                }
            />
            <Route exact path='/not-authorized' element={<NotAuthorized/>}>
            </Route>

        </Routes>
    )
}


export default AvailableRoutes