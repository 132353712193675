import {
    CITY_OVERALL_UPDATED,
    CITY_STARTED_AT_UPDATED,
    CITY_STEP_UPDATED, GET_CITY_USERMETA_CONTEXT,
    GET_USER_META, GET_USER_VOTINGS, SET_CURRENT_AUDIO_FILE, UPDATE_FINAL_RATING,
    USER_AUTH_ERROR, USER_FINISHED_TUTORIAL
} from '../types'

// eslint-disable-next-line import/no-anonymous-default-export
export default (state, action) => {
    switch (action.type) {
        case GET_USER_META:
            return {
                ...state,
                isAuthenticated: true,
                loading: false,
                userMeta: action.payload
            }
        case USER_AUTH_ERROR:
            return {
                ...state,
                loading: false,
                isAuthenticated: false
            };
        case CITY_STEP_UPDATED:
            const tmp = state.userMeta;
            tmp[action.city] = action.step
            return {
                ...state,
                userMeta: tmp
            }
        case CITY_STARTED_AT_UPDATED:
            const time = state.userMeta;
            time[action.city] = action.updater
            return {
                ...state,
                userMeta: time
            }
        case CITY_OVERALL_UPDATED:
            const tmpOverall = state.userMeta
            tmpOverall[action.cityCol] = action.city
            tmpOverall[action.stepCol] = action.step
            return {
                ...state,
                userMeta: tmpOverall
            }
        case GET_USER_VOTINGS:
            return{
                ...state,
                userVotings: action.payload,
                loadingUserVotings: false
            }
        case UPDATE_FINAL_RATING:
            state.userMeta['finished_voting'] = true
            return{
                ...state,
            }
        case SET_CURRENT_AUDIO_FILE:
            return {
                ...state,
                currentAudioSrc: action.payload
            }
        case GET_CITY_USERMETA_CONTEXT:
            return {
                ...state,
                cityUserMetaContext:action.payload,
            }
        case USER_FINISHED_TUTORIAL:
            return{
                ...state,
                loading:true
        }
        default:
            return state;

    }
};