import React, {useContext, useRef, useState} from "react";
import {useLocation, useNavigate, useParams, useSearchParams} from "react-router-dom";
import Button from "@mui/material/Button";
import UserContext from "../../context/user/userContext";
import Page from "../Page";
import {Avatar, BottomNavigation, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton} from "@mui/material";
import Layout from "../Layout";
import Box from "@mui/material/Box";
import parse from 'html-react-parser'
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import AdjustingButtonGrid from "./pagedetails/AdjustingButtonGrid";
import AdjustingButtonBox from "./pagedetails/AdjustingButtonBox";
import ContentBox from "./pagedetails/ContentBox";
import {styled} from "@mui/material/styles";
import * as PropTypes from "prop-types";
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';

const BootstrapDialog = styled(Dialog)(({theme}) => ({
    '& .MuiPaper-root': {
        borderRadius: "20px",
        display: "flex",
        alignItems: "center",
        minWidth:{xs:"80%",md:"50%",xl:"30%",lg:"30%"},
        width:"100%"
    },
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
    '& .MuiBox-root':{
        marginBottom:"5%"
    }
}));

function BootstrapDialogTitle(props) {
    const {children, onClose, ...other} = props;

    return (
        <DialogTitle sx={{m: 0, p: 2}} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseOutlinedIcon/>
                </IconButton>
            ) : null}
        </DialogTitle>
    );
}

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};

const CityDetail = () => {
    const userContext = useContext(UserContext)
    const {userMeta, setUserStep, setUserOverallStep, cityUserMetaContext, loadUserVotings,cities ,setCurrentAudioSrc} = userContext

    const [openDialog, setOpenDialog] = useState(false);

    const dialogOpen = () => {
        setOpenDialog(true);
    }

    const dialogClose = () => {
        setOpenDialog(false);
    }

    const contentRef = useRef(null);

    const location = useLocation();

    const params = useParams();
    const [searchParams, setSearchParams] = useSearchParams();
    const {city} = (location.state !== null) ? location.state: {city: cities.find(city => city.cityId == params.cityId)}


    const currentPage = city.pages.find(page => page.id == params.cityStep)
    const nextCity = cityUserMetaContext.find(item => (item.cityId > city.cityId && !item.userVoting));

    const navigate = useNavigate();



    const navVote = () => {
        const {cityId} = city;
        loadUserVotings();
        setCurrentAudioSrc(null)
        navigate(`/vote/${cityId}/?uuid=${searchParams.get('uuid')}`, {state: {"city": city}})
    }

    const navDashboard = () => {
        setCurrentAudioSrc(null)
        navigate(`/?uuid=${searchParams.get('uuid')}`)

    }

    const navNextCity = () => {
        if (nextCity.userProgress === null) {
            navigate(`/city-detail/${nextCity.cityId}/1/?uuid=${searchParams.get('uuid')}`, {
                state: {
                    city: nextCity,
                }
            });
        } else if (nextCity.userProgress < nextCity.pages.length) {
            navigate(`/city-detail/${nextCity.cityId}/${nextCity.userProgress}/?uuid=${searchParams.get('uuid')}`, {
                state: {
                    city: nextCity,
                }
            });
        } else if (nextCity.userProgress === nextCity.pages.length && nextCity.countryCode !== userMeta.country) {
            navigate(`/vote/${nextCity.cityId}/?uuid=${searchParams.get('uuid')}`, {
                state: {
                    city: nextCity,
                }
            });
            loadUserVotings();
        } else {
            navigate(`/?uuid=${searchParams.get('uuid')}`)
        }
        dialogClose();
    }
    const nextStep = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });

        const {cityId} = city
        const nextPage = currentPage.nextId
        const cityAccess = "city" + cityId + "_progress";
        if (userMeta[cityAccess] < currentPage.nextId || userMeta[cityAccess] == null) {
            setUserStep(cityId, currentPage.nextId);
        }
        const nextStep = city.pages.find(step => step.id === nextPage.id);
        if (nextStep) {
            setCurrentAudioSrc(nextStep.audioSrc)
        }
        setUserOverallStep(cityId, nextPage)

        /* if(city.pages[nextPage].audioSrc) setCurrentAudioSrc(city.pages[nextPage].audioSrc);
         else  setCurrentAudioSrc("");*/

        navigate(`/city-detail/${cityId}/${nextPage}/?uuid=${searchParams.get('uuid')}`, {
            state: {
                city: city,
            }
        });
    }

    const prevStep = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });

        const {cityId} = city
        const nextPage = currentPage.prevId
        setUserOverallStep(cityId, nextPage)
        const audioFile = city.pages[nextPage].audioSrc;
        setCurrentAudioSrc(audioFile);
        navigate(`/city-detail/${cityId}/${nextPage}/?uuid=${searchParams.get('uuid')}`, {
            state: {
                city: city,
            }
        });
    }

    return (
        <Layout audioSrc={currentPage.audioSrc} totalSteps={city.pages.length} currentPosition={currentPage.id}
                cityName={city.cityName}>
            <>
                <Page>
                    <ContentBox ref={contentRef}>
                        {currentPage.id === 1 &&
                            <Grid item xs={10}>
                                <Card
                                    sx={{
                                        borderRadius: "20px",
                                        border: "0.5pt solid #E6E6E6",
                                        display: "flex",
                                        minHeight: {xs: "25vmax", lg: "6vmax"},
                                        minWidth: {xs: "35vmax", lg: "45vmax"},
                                        maxHeight: {xs: "30vmax", lg: "10vmax"},
                                        maxWidth: {xs: "40vmax", lg: "50vmax"},
                                        mb: 3,
                                        mt: 2,
                                        paddingTop: {xs: "20px", lg: 0},
                                        paddingBottom: {xs: "20px", lg: 0},
                                        backgroundColor: "#F9F9F9"
                                    }}
                                    elevation={10}
                                >
                                    <Grid
                                        container
                                        alignItems={"center"}
                                        flexDirection={{xs: "column", lg: "row"}}
                                        justifyContent={"flex-start"}
                                    >
                                        <Grid item xs={"auto"} lg={4}>
                                            <Box
                                                sx={{position: 'relative'}}
                                                width={{xs: "15vmax", lg: "10vmax"}}
                                                height={{xs: "15vmax", lg: "10vmax"}}
                                            >
                                                {/*  <CardMedia
                                                        component="img"
                                                        image="https://mui.com/static/images/cards/contemplative-reptile.jpg"
                                                    />*/}
                                                <Avatar sx={{
                                                    padding: {md: "5%"},
                                                    width: "100%",
                                                    height: "100%",
                                                }}
                                                        className={"ecod-city-detail-img"}
                                                        src={city.cityImage}/>
                                                {/* <Box display={"flex"} alignItems={"center"}
                                                         justifyContent={"center"}
                                                         sx={{
                                                             position: 'absolute',
                                                             bottom: 0,
                                                             left: 0,
                                                             width: '100%',
                                                             height: '100%',
                                                             borderRadius: '50%',
                                                             bgcolor: 'rgba(0, 0, 0, 0.4)',
                                                             color: 'white',
                                                             padding: '10px',
                                                         }}
                                                    >
                                                        <Typography variant="h5">{city.abbr}</Typography>
                                                    </Box>*/}
                                            </Box>
                                        </Grid>
                                        <Grid item alignItems={"flex-start"}>
                                            <Typography variant={"h5"}>{city.cityName}</Typography>
                                        </Grid>
                                    </Grid>
                                </Card>
                            </Grid>
                        }

                        {currentPage.videoSrc &&
                            <Grid item
                                  sx={{justifyContent: {lg: "flext-start"}}}
                                  alignItems={"center"} justifyContent={"center"} display={"flex"}>
                                <Box
                                    minHeight={"25vmax"}
                                    minWidth={"34vmax"}
                                    maxWidth={"35vmax"}
                                >
                                    <video controls height={"100%"} width={"100%"}>
                                        <source src={currentPage.videoSrc} type="video/mp4"></source>
                                    </video>
                                </Box>
                            </Grid>
                        }

                        <Grid item xs={12} lg={12} md={12}>
                            <Box className={"ecod-city-detail-wrapper"}>
                                {parse(currentPage.heading)}
                                {parse(currentPage.content)}
                            </Box>
                        </Grid>

                    </ContentBox>

                </Page>
                <BottomNavigation
                    sx={{position: "fixed", bottom: "0px", paddingBottom: "2%", width: "100%", height: "auto"}}>
                    <AdjustingButtonGrid>
                        {currentPage.prevId &&
                            <AdjustingButtonBox>
                                <Button className={"button-secondary"} onClick={() => prevStep()}
                                        fullWidth={true}
                                        variant={"outlined"}>
                                    Back
                                </Button>
                            </AdjustingButtonBox>
                        }

                        {currentPage.nextId &&
                            <AdjustingButtonBox>
                                <Button className={"button-primary"}
                                        fullWidth={true}
                                        variant={"contained"} onClick={() => nextStep()}

                                >
                                    Next
                                </Button>
                            </AdjustingButtonBox>
                        }
                        {!currentPage.nextId && city.countryCode !== userMeta.country && !userMeta.finished_voting &&
                            <AdjustingButtonBox>
                                <Button onClick={() => navVote()} className={"button-primary"}
                                        fullWidth={true}
                                        variant={"contained"}>
                                    Vote
                                </Button>
                            </AdjustingButtonBox>
                        }
                        {!currentPage.nextId && city.countryCode == userMeta.country &&
                            <AdjustingButtonBox>
                                <Button onClick={() => dialogOpen()}
                                        fullWidth={true}
                                        variant={"outlined"}
                                        sx={{color: '#929292', borderColor: '#929292',  borderRadius: "20px", fontSize: "1.1rem", textTransform: "none"}}
                                        >
                                    Vote
                                </Button>
                            </AdjustingButtonBox>
                        }
                        {!currentPage.nextId && city.countryCode != userMeta.country && userMeta.finished_voting &&
                            <AdjustingButtonBox>
                                <Button onClick={() => navDashboard()} className={"button-primary"}
                                        fullWidth={true}
                                        variant={"contained"}>
                                    Dashboard
                                </Button>
                            </AdjustingButtonBox>
                        }
                    </AdjustingButtonGrid>
                </BottomNavigation>
                <BootstrapDialog
                    onClose={dialogClose}
                    aria-labelledby="customized-dialog-title"
                    open={openDialog}
                >
                    <BootstrapDialogTitle id="customized-dialog-title" onClose={dialogClose}>
                    </BootstrapDialogTitle>

                    <Box width={"80%"}>
                    <DialogContent sx={{textAlign: "center"}}>
                        <Typography variant={"h5"} mb={4}> Please note:  </Typography>
                        <Typography variatnt={"body2"} gutterBottom>
                            You can only rate cities outside your country of residence.
                        </Typography>
                    </DialogContent>
                    <DialogActions sx={{display: "flex", width: "100%", alignItems: "center", justifyContent: "center", padding: "10",mb:"10px"}} >
                        <Grid container rowSpacing={1.5} alignItems={"center"} justifyContent={"center"} direction={"column"}>
                            <Grid item width={{xs: "100%", lg: "60%"}}> 
                                <Button variant={"contained"} fullWidth={true} className={"button-primary"}
                                        onClick={navDashboard}>
                                    Dashboard
                                </Button>
                            </Grid>
                            {nextCity &&
                            <Grid item width={{xs: "100%", lg: "60%"}}>
                                <Button variant={"contained"} fullWidth={true} className={"button-primary"}
                                        onClick={navNextCity}>
                                    Next City
                                </Button>
                            </Grid>
                            }
                        </Grid>
                    </DialogActions>
                    </Box>
                </BootstrapDialog>
            </>
        </Layout>
    )
}

export default CityDetail;
