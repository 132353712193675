import axios from "axios";

const setAuthHeader = uuid => {
    if(uuid){
        axios.defaults.headers.common['uuid']= uuid
    }else{
        delete axios.defaults.headers.common['uuid']
    }
}

export default setAuthHeader;