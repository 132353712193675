import React, {useContext, useEffect, useState} from 'react'
import Rating from '@mui/material/Rating';
import {
    BottomNavigation,
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    Grid,
    IconButton,
    Stack
} from "@mui/material";
import Page from '../Page';
import AdditionalFeedbackJson from '../../res/additionalFeedbackJson'
import AdditionalFeedbackJsonFive from '../../res/additionalFeedbackFive'
import Box from "@mui/material/Box";
import UserContext from "../../context/user/userContext";
import {useLocation, useNavigate, useParams, useSearchParams} from "react-router-dom";
import Layout from "../Layout";
import Typography from "@mui/material/Typography";
import {styled} from "@mui/material/styles";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import AdjustingButtonGrid from './pagedetails/AdjustingButtonGrid';
import AdjustingButtonBox from './pagedetails/AdjustingButtonBox';
import ContentBox from "./pagedetails/ContentBox";
import {DEADLINE_VOTING} from "../../context/types";

const BootstrapDialog = styled(Dialog)(({theme}) => ({
    '& .MuiPaper-root': {
        borderRadius: "20px",
        display: "flex",
        alignItems: "center",
        minWidth: {xs: "80%", md: "50%", xl: "30%", lg: "30%"},
        width: "100%"
    },
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
    '& .MuiBox-root': {
        marginBottom: "5%"
    }
}));

const Vote = () => {
    const userContext = useContext(UserContext)
    const {
        setUserVoting,
        userVotings,
        loadUserVotings,
        loadingUserVotings,
        userMeta,
        cities,
        cityUserMetaContext,
        setUserOverallStep,
        setUserCityStartedAt,
    } = userContext

    const [open, setOpen] = useState(false);

    const [openFirst, setOpenFirst] = useState(false);
    const [openDeadline, setOpenDeadline] = useState(false);

    const [starValue, setStarValue] = useState(0);
    const [additionalFeedback, setAdditionalFeedback] = useState(AdditionalFeedbackJson)

    const [additionalFeedbackFive, setAdditionalFeedbackFive] = useState(AdditionalFeedbackJsonFive)

    const location = useLocation();


    const [searchParams] = useSearchParams();
    const params = useParams();

    const {city} = (location.state !== null) ? location.state: {city: cities.find(city => city.cityId == params.cityId)}
    const updateStars = (el) => {
        setStarValue(parseInt(el.target.value))
    }
    const closeFirst = () => {
        setOpenFirst(false);
    }

    const closeDeadline = () => {
        navigate(`/?uuid=${searchParams.get('uuid')}`);
    }

    const navigate = useNavigate();
    const goBackToCity = () => {
        navigate(`/city-detail/${city.cityId}/${city.pages.length}/?uuid=${searchParams.get('uuid')}`, {
            state: {
                city: city,
            }
        });
    }

    const navigateToDashboard = () => {
        const nextStep = cityUserMetaContext.find(item => (!item.userVoting));

        console.log(nextStep);
        if (nextStep) {
            let city_progress = userMeta[`city${nextStep.cityId}_progress`]
            if (!city_progress) {
                city_progress = 1;
            }

            setUserOverallStep(nextStep.cityId, city_progress);
        }
        navigate(`/?uuid=${searchParams.get('uuid')}`);
    }

    const navigateToNextCity = () => {
        const nextStep = cityUserMetaContext.find(item => item.cityId > city.cityId)

        if (nextStep) {
            if (nextStep.userProgress === null) {

                const cityStartedAtKey = "city" + nextStep.cityId + '_startedAt';
                if (userMeta[cityStartedAtKey] == null) {
                    console.log(userMeta[cityStartedAtKey])
                    setUserCityStartedAt(nextStep.cityId)
                }

                navigate(`/city-detail/${nextStep.cityId}/1/?uuid=${searchParams.get('uuid')}`, {
                    state: {
                        city: nextStep,
                    }
                });
            } else if (nextStep.userProgress < nextStep.pages.length) {
                navigate(`/city-detail/${nextStep.cityId}/${nextStep.userProgress}/?uuid=${searchParams.get('uuid')}`, {
                    state: {
                        city: nextStep,
                    }
                });
            } else if (nextStep.userProgress === nextStep.pages.length && nextStep.countryCode !== userMeta.country) {
                navigate(`/vote/${nextStep.cityId}/?uuid=${searchParams.get('uuid')}`, {
                    state: {
                        city: nextStep,
                    }
                });
                loadUserVotings();
            } else {
                navigate(`/?uuid=${searchParams.get('uuid')}`)
            }
        } else {
            navigate(`/?uuid=${searchParams.get('uuid')}`)
        }

        setOpen(false)


    }

    const addBtn = (el) => {
        const btnIndx = additionalFeedback.findIndex(item => item.id == el.target.id);
        additionalFeedback[btnIndx].variant = (additionalFeedback[btnIndx].variant === 'contained') ? 'outlined' : 'contained';
        additionalFeedback[btnIndx].color = (additionalFeedback[btnIndx].color === "white") ? "#929292" : "white";
        setAdditionalFeedback([...additionalFeedback]);
    }

    const addBtnFive = (el) => {
        const btnIndx = additionalFeedback.findIndex(item => item.id == el.target.id);
        additionalFeedbackFive[btnIndx].variant = (additionalFeedbackFive[btnIndx].variant === 'contained') ? 'outlined' : 'contained';
        additionalFeedbackFive[btnIndx].color = (additionalFeedbackFive[btnIndx].color === "white") ? "#929292" : "white";
        setAdditionalFeedbackFive([...additionalFeedbackFive]);
    }

    const submitVote = () => {
        if (starValue === 1) {
            const submitFeedback = additionalFeedback.filter(item => item.variant === "contained")
            const stringifiedFeedback = submitFeedback.map(item => {
                return item
            })
            setUserVoting(params.cityId, starValue, stringifiedFeedback)
        } else if (starValue === 5) {
            const submitFeedback = additionalFeedbackFive.filter(item => item.variant === "contained")
            const stringifiedFeedback = submitFeedback.map(item => {
                return item
            })
            setUserVoting(params.cityId, starValue, stringifiedFeedback)
        } else {
            setUserVoting(params.cityId, starValue, "")
        }

        setOpen(true)
    }


    useEffect(() => {

        if (DEADLINE_VOTING <= Date.now()) {
            setOpenDeadline(false)
        }
        if (cityUserMetaContext.length) {
            let flag = false;
            cityUserMetaContext.forEach((city, i) => {
                if (city.userVoting != null) {
                    flag = true;
                }
            })
            if (!flag) {
                setOpenFirst(true)
            }
        }
        if (!loadingUserVotings || userVotings || params) {
            const cityIndx = userVotings.findIndex(item => item.city == params.cityId);

            if (userMeta.finished_voting) {
                navigate(`/?uuid=${searchParams.get('uuid')}`)
            }
            if (cityIndx >= 0) {
                setStarValue(parseInt(userVotings[cityIndx].rating))

                if (userVotings[cityIndx].rating === 1) {
                    additionalFeedback.forEach((el) => {
                        el.variant = 'outlined'
                        el.color = '#929292'
                    })
                    additionalFeedbackFive.forEach((el) => {
                        el.variant = 'outlined'
                        el.color = '#929292'
                    })
                    setAdditionalFeedbackFive([...additionalFeedbackFive])
                    if (userVotings[cityIndx].additional_feedback != null && userVotings[cityIndx].additional_feedback.length > 0) {
                        userVotings[cityIndx].additional_feedback.forEach((el, i) => {
                            const currIndex = additionalFeedback.findIndex(item => item.id == el.id)

                            if (currIndex >= 0) {
                                el.variant = "contained"
                                el.color = "white"
                                additionalFeedback[currIndex] = el
                            } else {
                                additionalFeedback[i].variant = "outlined";
                                el.color = "#929292"
                            }
                            setAdditionalFeedback([...additionalFeedback])
                        })
                    }
                } else if (userVotings[cityIndx].rating === 5) {
                    additionalFeedbackFive.forEach((el) => {
                        el.variant = 'outlined'
                        el.color = '#929292'
                    })
                    additionalFeedback.forEach((el) => {
                        el.variant = 'outlined'
                        el.color = '#929292'
                    })
                    setAdditionalFeedback([...additionalFeedback])
                    if (userVotings[cityIndx].additional_feedback != null && userVotings[cityIndx].additional_feedback.length > 0) {
                        userVotings[cityIndx].additional_feedback.forEach((el, i) => {
                            const currIndex = additionalFeedbackFive.findIndex(item => item.id == el.id)

                            if (currIndex >= 0) {
                                el.variant = "contained"
                                el.color = "white"
                                additionalFeedbackFive[currIndex] = el
                            } else {
                                additionalFeedbackFive[i].variant = "outlined";
                                el.color = "#929292"
                            }
                            setAdditionalFeedbackFive([...additionalFeedbackFive])
                        })
                    }
                }
            }
        }
    }, [loadingUserVotings, userVotings, params, cityUserMetaContext])


    if (!userMeta.finished_voting && city.countryCode !== userMeta.country) {
        return (
            (loadingUserVotings) ? <CircularProgress></CircularProgress> :
                <>
                    <Layout audioSrc={null} cityName={city.cityName}>
                        <Page>
                            <ContentBox
                                /*    sx={{
                                        overflow: "autoY",
                                        maxWidth: {lg: "50vw",xl:"50vw"},
                                        display: "flex",
                                        flexDirection:"column",
                                        alignItems: "center",
                                        justifyContent: "center",
                                    }}*/ mb={3}>
                                <Grid container alignItems={"center"} flexDirection={"column"} mb={5}>
                                    <Grid item>
                                        <Box sx={{position: 'relative', marginTop: "15%", marginBottom: "5%"}}>
                                            <img width={"150vmax"}  src={city.cityImage}
                                                 alt={"city image"}></img>
                                        </Box>
                                    </Grid>

                                    <Grid mt={2} item xs={12} md={12} lg={12}>
                                        <Typography variant={"h4"}> {city.cityName}</Typography>
                                    </Grid>
                                    <Grid item xs={12} md={12} lg={12}>
                                        <Box mt={2}>
                                            <FormControl>
                                                <Stack color={"action"} sx={{transform: "scale(1.4)", color: "#182A3B"}}
                                                       spacing={1} onChange={updateStars}>
                                                    <Rating sx={{color: '#182A3B', borderColor: '#182A3B'}}
                                                            name="size-large"
                                                            defaultValue={0} value={starValue}
                                                            size="large"/>
                                                </Stack>
                                            </FormControl>
                                            <Grid mt={2} item xs={12} md={12} lg={12}>
                                                <Typography variant={"h5"}> {starValue}/5</Typography>
                                            </Grid>
                                        </Box>
                                    </Grid>
                                    {starValue === 1 &&
                                        <Grid container spacing={1} justifyContent={"center"}>
                                            <Grid item mt={2} xs={10} lg={8} md={8}>
                                                <Typography variant={"body1"}>
                                                    Please tell us why you rated this city with one star.
                                                </Typography>
                                            </Grid>
                                            <Grid container item mt={2} xs={12} lg={10} md={10} spacing={1}
                                                  justifyContent={"center"}>
                                                {additionalFeedback.map((feedback, key) => {
                                                    return (
                                                        <Grid item key={key}>
                                                            <Button onClick={addBtn} key={key}
                                                                    className={"button-feedback"}
                                                                    style={{color: `${feedback.color}`}}
                                                                    value={feedback.value}
                                                                    id={feedback.id}
                                                                    variant={feedback.variant}
                                                            >
                                                                {feedback.name}
                                                            </Button>
                                                        </Grid>
                                                    )
                                                })
                                                }
                                            </Grid>
                                        </Grid>
                                    }
                                    {starValue === 5 &&
                                        <Grid container spacing={1} justifyContent={"center"}>
                                            <Grid item mt={2} xs={10} lg={8} md={8}>
                                                <Typography variant={"body1"}>
                                                    Please tell us why you rated this city with five stars.
                                                </Typography>
                                            </Grid>
                                            <Grid container item mt={2} xs={12} lg={10} md={10} spacing={1}
                                                  justifyContent={"center"}>
                                                {additionalFeedbackFive.map((feedback, key) => {
                                                    return (
                                                        <Grid item key={key}>
                                                            <Button onClick={addBtnFive} key={key}
                                                                    className={"button-feedback"}
                                                                    style={{color: `${feedback.color}`}}
                                                                    value={feedback.value}
                                                                    id={feedback.id}
                                                                    variant={feedback.variant}
                                                            >
                                                                {feedback.name}
                                                            </Button>
                                                        </Grid>
                                                    )
                                                })
                                                }
                                            </Grid>
                                        </Grid>
                                    }
                                </Grid>
                            </ContentBox>
                            <BottomNavigation sx={{
                                position: "fixed",
                                bottom: "0px",
                                paddingBottom: "2%",
                                width: "100%",
                                height: "auto"
                            }}>
                                <AdjustingButtonGrid>
                                    <AdjustingButtonBox>
                                        <Button fullWidth={true} className={"button-secondary"}
                                                onClick={goBackToCity} variant={"outlined"}
                                        >Back</Button>
                                    </AdjustingButtonBox>
                                    <AdjustingButtonBox>
                                        <Button fullWidth={true} className={"button-primary"}
                                                disabled={!starValue}
                                                onClick={submitVote} variant={"contained"}>Save</Button>
                                    </AdjustingButtonBox>
                                </AdjustingButtonGrid>
                            </BottomNavigation>
                        </Page>

                        <BootstrapDialog
                            aria-labelledby="customized-dialog-title"
                            open={open}
                        >
                            <Box width={"80%"}>
                                <DialogContent sx={{textAlign: "center"}}>
                                    <Typography variant={"h5"} mb={4}> Thank you </Typography>
                                    <Typography variatnt={"body2"} gutterBottom>
                                        You can either continue with the next city or return to the dashboard.
                                    </Typography>
                                </DialogContent>
                                <DialogActions>
                                    <Grid container flexDirection={"column"} spacing={2}>
                                        <Grid item>
                                            <Button variant={"outlined"} fullWidth={true} className={"button-secondary"}
                                                    onClick={navigateToDashboard}>
                                                Dashboard
                                            </Button>
                                        </Grid>
                                        <Grid item>
                                            <Button variant={"contained"} fullWidth={true} className={"button-primary"}
                                                    onClick={navigateToNextCity}
                                            >
                                                Continue
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </DialogActions>
                            </Box>
                        </BootstrapDialog>
                        <BootstrapDialog
                            aria-labelledby="customized-dialog-title"
                            open={openFirst}
                        >
                            <Box width={"100%"}>
                                <DialogContent sx={{textAlign: "center"}}>
                                    <Typography variant={"h5"} mb={4}> Information: </Typography>
                                    <Typography variatnt={"body2"} textAlign={"left"}>
                                        <ul className={"ecod-popup-ul"}>
                                            <li>You can rate cities with 1 to 5 stars (1 star = lowest, 5 stars =
                                                highest)
                                            </li>
                                            <li>For 1 and 5 star rankings, you can optionally select the most relevant
                                                factors
                                            </li>
                                            <li>When you have viewed and rated all cities, you can adjust your ratings
                                            </li>
                                            <li>Please send your ratings until 3 December 2023, 23:59h (CET).</li>
                                        </ul>
                                    </Typography>
                                </DialogContent>
                                <DialogActions>
                                    <Grid container flexDirection={"column"} spacing={2}>
                                        <Grid item>
                                            <Button variant={"outlined"} fullWidth={true} className={"button-secondary"}
                                                    onClick={closeFirst}>
                                                Close
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </DialogActions>
                            </Box>
                        </BootstrapDialog>

                        <BootstrapDialog
                            aria-labelledby="customized-dialog-title"
                            open={openDeadline}
                        >
                            <Box width={"100%"}>
                                <DialogContent sx={{textAlign: "center"}}>
                                    <Typography variant={"h5"} mb={4}> Information: </Typography>
                                    <Typography variatnt={"body2"} textAlign={"left"}>
                                        Ratings had to be submitted until the 3rd of December 2023, 23:59h (CET).
                                    </Typography>
                                </DialogContent>
                                <DialogActions>
                                    <Grid container flexDirection={"column"} spacing={2}>
                                        <Grid item>
                                            <Button variant={"outlined"} fullWidth={true} className={"button-secondary"}
                                                    onClick={closeDeadline}>
                                                Close
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </DialogActions>
                            </Box>
                        </BootstrapDialog>
                    </Layout>
                </>
        )
    }

    if (city.countryCode === userMeta.country) {
        return (
            <Layout>
                <p>Sorry you are not allowed to vote for cities of your country</p>
            </Layout>
        )
    }

    if (userMeta.finished_voting) {
        return (
            <Layout>
                <p>You already submitted your final vote</p>
            </Layout>
        )
    }


}


export default Vote
